import React from 'react';
import { UISref } from '@uirouter/react';
import { translate } from 'react-translate';
import classNames from 'classnames';

const Button = (props) => {
	const {
		t,
		name,
		disabled,
		onClick,
		href = false,
		className = '',
		target = '_new',
	} = props;

	let container = classNames(
		'btn',
		{
			disabled: disabled,
		},
		className
	);

	const getContent = () => {
		return (
			<div className="btn-content">
				<span>{t(name)}</span>
			</div>
		);
	};

	if (href)
		return (
			<UISref to={href}>
				<a
					href="#"
					onClick={onClick ? onClick : null}
					className={container}
					disabled={disabled}
				>
					{getContent()}
				</a>
			</UISref>
		);
	else
		return (
			<button
				onClick={onClick ? onClick : null}
				disabled={disabled}
				className={container}
			>
				{getContent()}
			</button>
		);
};

export default translate('buttons')(Button);
