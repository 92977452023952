import React, { useState } from 'react';
import Modal from 'react-modal';
import Button from '../form/Button';
import { customStyles } from '../../config/const';

const ModalConfirm = (props) => {
	const { visible = false, onClose, action, params } = props;
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState({ error: false, message: '' });

	const closeModal = (reload = false) => {
		if (onClose) onClose(reload);
	};

	const getDisabled = () => {
		let disabled = false;
		if (loading) disabled = true;
		return disabled;
	};
	const callAction = () => {
		if (typeof action === 'function') {
			setLoading(true);
			setError({ error: false, message: '' });
			action(params)
				.then((response) => {
					setLoading(false);
					closeModal(response);
				})
				.catch((err) => {
					setError({
						error: true,
						message: err.message,
					});
					setLoading(false);
				});
		} else return false;
	};

	return (
		<Modal
			isOpen={visible ? true : false}
			onRequestClose={() => closeModal(false)}
			style={customStyles}
			className="modal"
			contentLabel=""
			ariaHideApp={false}
		>
			<div className="modal-body">
				<h1>¿Esta seguro de eliminar este elemento?</h1>
			</div>
			<div className="modal-footer mt-3">
				<Button
					onClick={() => closeModal(false)}
					className="mr-3 btn-disabled"
					name="cancel"
				/>
				<Button
					name={getDisabled() ? 'loading' : 'delete'}
					onClick={callAction}
					disabled={getDisabled()}
				/>
			</div>
			{error.error ? (
				<div className="error-message mt-3">{error.message}</div>
			) : null}
		</Modal>
	);
};

export default ModalConfirm;
