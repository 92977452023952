import React, { useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Input from '../../components/form/Input';
import Button from '../../components/form/Button';
import { login } from '../../redux/actions';
import { connect } from 'react-redux';

const Login = (props) => {
	const { $transition$ } = props;
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState({ error: false, message: '' });

	const send = () => {
		setLoading(true);

		setError({
			error: false,
			message: '',
		});

		props
			.login({
				email,
				password,
			})
			.then((response) => {
				setLoading(false);
				if (response.user.rol !== 'admin')
					$transition$.router.stateService.go('admin.donate');
				else $transition$.router.stateService.go('admin.home');
			})
			.catch((err) => {
				setError({
					error: true,
					message: err.message,
				});
				setLoading(false);
			});
	};
	return (
		<div>
			<Header />
			<div className="wrapper">
				<div className="container">
					<div className="row pt-3">
						<div className="col col-12 col-sm-8 offset-sm-2">
							<h1 className="text-center">Iniciar sesión</h1>
						</div>
					</div>
					<div className="row pt-3">
						<div className="col col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
							<div className="box-shadow">
								<Input
									name="email"
									type="email"
									value={email}
									onChange={setEmail}
								/>
								<Input
									type="password"
									name="password"
									value={password}
									onChange={setPassword}
								/>
								<Button
									disabled={loading}
									onClick={send}
									className="el-center"
									name={loading ? 'loading' : 'login'}
								/>

								<div className="col-12 mt-4">
									{error.error ? (
										<div className="error-message">{error.message}</div>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default connect(null, { login })(Login);
