import React, { useState } from 'react';
import Modal from 'react-modal';
import { customStyles } from '../../config/const';
import { translate } from 'react-translate';
import Button from '../form/Button';
const ModalReport = (props) => {
	const { visible = false, onClose, folio = 'Y20300005', t } = props;

	const closeModal = (reload = false) => {
		if (onClose) onClose(reload);
	};

	return (
		<Modal
			isOpen={visible ? true : false}
			onRequestClose={() => closeModal(false)}
			style={customStyles}
			className="modal"
			contentLabel=""
			ariaHideApp={false}
		>
			<button onClick={() => closeModal(false)} className="modal-close">
				<i className="material-icons">close</i>
			</button>
			<div className="modal-header mt-4">
				<img
					width={180}
					className="el-center"
					src="/assets/images/logoyucatan.svg"
				/>
			</div>
			<div className="modal-body mt-4 mb-4">
				<h1 className="mt-5">{t('sendsuccess')}</h1>
				<p className="text-center mb-0 pb-0">{t('thanks')}</p>
				<p className="text-center pt-0 mt-0">{t('folio')}</p>
				<h3 className="text-center mb-5">#{folio}</h3>
				<Button
					onClick={() => closeModal(false)}
					className="el-center"
					name="accept"
				/>
			</div>
		</Modal>
	);
};

export default translate('modal')(ModalReport);
