import React, { Component } from 'react';
import { UIRouter, UIView, pushStateLocationPlugin } from '@uirouter/react';
import { connect } from 'react-redux';

import Layout from './screens/Layout';
import Home from './screens/Home';
import WantSupport from './screens/WantSupport';
import NeedSupport from './screens/NeedSupport';
import DonateNow from './screens/DonateNow';
import Organizasiones from './screens/Organizasiones';
import Historias from './screens/Historias';
import Aviso from './screens/Aviso';

import moment from 'moment';
//admin views
import Login from './screens/account/Login';

//admin

import LayoutAdmin from './screens/admin/LayoutAdmin';
import HomeAdmin from './screens/admin/HomeAdmin';
import Supportreceived from './screens/admin/Supportreceived';
import Detail from './screens/admin/Detail';
import Donations from './screens/admin/Donations';
import Gallery from './screens/admin/Gallery';
import AddGallery from './screens/admin/AddGallery';
import GallerySingle from './screens/admin/GallerySingle';

const esLocale = require('moment/locale/es');
moment.updateLocale('es', esLocale);

const states = [
	{
		name: 'layout',
		component: Layout,
	},
	{
		name: 'layout.home',
		url: '/',
		component: Home,
		data: {
			title: 'Yucatán Solidario',
		},
	},
	{
		name: 'layout.donatenow',
		url: '/donar-ahora',
		component: DonateNow,
		data: {
			title: 'Yucatán Solidario - Donar ahora',
		},
	},
	{
		name: 'layout.wantsupport',
		url: '/quiero-ayudar',
		component: WantSupport,
		data: {
			title: 'Yucatán Solidario - Quiero ayudar',
		},
	},
	// {
	// 	name: 'layout.needsupport',
	// 	url: '/quiero-recibir-apoyo',
	// 	component: NeedSupport,
	// 	data: {
	// 		title: 'Yucatán Solidario - Necesito apoyo',
	// 	},
	// },
	{
		name: 'layout.historias',
		url: '/historias-de-exito',
		component: Historias,
		data: {
			title: 'Yucatán Solidario - Historias de éxito',
		},
	},
	{
		name: 'layout.orgs',
		url: '/organizaciones',
		component: Organizasiones,
		data: {
			title: 'Yucatán Solidario - Organizaciones',
		},
	},
	{
		name: 'layout.aviso',
		url: '/aviso-de-privacidad',
		component: Aviso,
		data: {
			title: 'Aviso de privacidad',
		},
	},
	//admin views
	{
		name: 'login',
		url: '/iniciar-sesion',
		component: Login,
		data: {
			title: 'Yucatán Solidario - Iniciar sesión',
		},
	},
	{
		name: 'admin',
		url: '/admin',
		component: LayoutAdmin,
		data: {
			title: 'Administrador',
			authenticate: true,
		},
	},
	{
		name: 'admin.home',
		url: '/',
		component: HomeAdmin,
		data: {
			title: 'Yucatán Solidario - Administrador',
		},
	},
	{
		name: 'admin.supportreceived',
		url: '/apoyos-recibidos?{page:int}&{s:string}&{status:string}',
		params: {
			page: 1,
			s: '',
			status: '0',
		},
		component: Supportreceived,
		data: {
			title: 'Administrador - Apoyos recibidos',
		},
	},
	{
		name: 'admin.gallery',
		url: '/galeria?{page:string}',
		params: {
			page: '1',
		},
		component: Gallery,
		data: {
			title: 'Yucatán Solidario - Galleria',
		},
	},
	{
		name: 'admin.newgallery',
		url: '/galeria/nuevo',
		component: AddGallery,
		data: {
			title: 'Yucatán Solidario - Galleria',
		},
	},
	{
		name: 'admin.singlegallery',
		url: '/galeria/:id',
		component: GallerySingle,
		data: {
			title: 'Yucatán Solidario - Detalle',
		},
	},
	{
		name: 'admin.donate',
		url:
			'/donativos?{page:int}&{organization:string}&{startDate:string}&{endDate:string}',
		params: {
			page: 1,
			startDate: moment().startOf('month').format('DD-MM-YYYY'),
			endDate: moment().endOf('month').format('DD-MM-YYYY'),
			organization: '0',
		},
		component: Donations,
		data: {
			title: 'Administrador - Donativos',
		},
	},
	{
		name: 'admin.detail',
		component: Detail,
		url: '/apoyo/:id',
		data: {
			title: 'Detalle del apoyo',
		},
	},
];

const plugins = [pushStateLocationPlugin];

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLogin: props.isLogin,
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			isLogin: nextProps.isLogin,
		});
	}

	onSuccess(trans, state) {
		window.scrollTo(0, 0);
		let { $current = {} } = trans.router.stateService;
		document.title =
			$current.data && $current.data.title ? $current.data.title : 'Yucatan';

		if (window.ga) {
			window.ga('create', 'UA-139156466-8');
			window.ga('send', {
				hitType: 'pageview',
				page: window.location.pathname.toString(),
			});
		}
	}

	onBefore(trans, state) {
		let $state = trans.router.stateService;
		if (!this.state.isLogin)
			return $state.target('login', undefined, { location: true });
		else return true;
	}

	render() {
		return (
			<UIRouter
				plugins={plugins}
				states={states}
				config={(router) => {
					router.urlRouter.otherwise({ state: '404' });
					router.transitionService.onSuccess(true, this.onSuccess.bind(this));
					router.transitionService.onBefore(
						{
							to: (state) => state.data && state.data.authenticate,
						},
						this.onBefore.bind(this),
						{ priority: 10 }
					);
				}}
			>
				<UIView />
			</UIRouter>
		);
	}
}

const MapStateToProps = ({ token, user, business }) => {
	return {
		isLogin: token ? true : false,
	};
};

export default connect(MapStateToProps)(App);
