import {  CHANGE_MODAL } from '../types';

const changeModal = (state = { open : true , date : new Date() }, action) => {
	switch (action.type) {
		case  CHANGE_MODAL : {
			return { open : action.status, date : new Date() };
		}
		default : {
			return state;
		}
	}
}

export default changeModal;