import React, { Component } from 'react';
import Modal from 'react-modal';
import Button from '../components/form/Button';
const customStyles = {
	overlay : {
		zIndex : 9999,
		boderRadius : '0px !important',
		backgroundColor : 'rgba(0,0,0,0.7)',
		overflow : 'scroll',
		overflowX  : 'hidden'
	},
	content : {
		zIndex : 9999,
		boderRadius : '0px !important',
		top : '70px',
		padding: '15px',
		left : 'calc( (100vw - 800px) / 2 )',
		width : 800,
		height :'auto',
		overflow : 'hidden',
		border : 'none',
		backgroundColor : 'rgb(255,255,255)',
		bottom : false,
		padding : 0
	}
}

class ModalOpening extends Component{
	constructor(props) {
		super(props);
		this.state = {};
	}
	
	_onClose(){
		this.props.onClose();
	}

	_closeModal(){	
		this._onClose();
	}
	
	componentDidMount() {
	    this.timeout = setTimeout(() => {
	     this.props.onClose();
	    }, 35000);
	  }

	  componentWillUnmount() {
	    clearTimeout(this.timeout);
	  }

	render(){

		return (<Modal
			ariaHideApp={false}
			contentLabel=""
			isOpen={this.props.visible}
			style={customStyles}
			onRequestClose={this._closeModal.bind(this)}
			closeOnEscape={true}
		>
			<div className="triangle"></div>
			<div className="contentmodal">
				<div className="col-12 d-flex justify-content-end">
					<div className="close-button pt-3">
						<i className="material-icons closes" onClick={this._closeModal.bind(this)} >close</i>
					</div>
				</div>
				<div className="col-12 d-flex">
					<img
							src="../assets/images/logoyucatan.svg"
							className="el-center img-responsive imgmodals"
						/>
				</div>
				<div className="col-12 d-flex flex-column">
					<p className="modal-opening-text">
						Ahora más que nunca necesitamos tu apoyo y solidaridad, para hacer frente a la <span className="moradot">Tormenta Tropical Cristobal</span> y las inundaciones que ha dejado a su paso por todo el territorio yucateco. <span className="boldtxt">Únete a los esfuerzos donando en especie en el DIF Estatal para coordinar tu valioso apoyo, con el cual saldremos adelante #UnidosComoUnoSolo</span>
					</p>
					<div className="text-center">
						<span className="boldtxt">Puedes donar los siguientes artículos:</span>
					</div>
				</div>
				<div className="row mb-3">
					<div className="col-12 col-sm-6 d-flex flex-column">
						<ul>
							<li>Agua purificada</li>
							<li>Alimentos no perecederos</li>
							<li>Artículos de higiene personal (Papel sanitario, jabón, toallas sanitarias, pasta dental)</li>
							<li>Cobertores</li>
							<li>Colchonetas, lonas y tarimas de madera</li>
						</ul>
					</div>
					<div className="col-12 col-sm-6 d-flex flex-column">
						<ul>
							<li>Insumos de limpieza (detergente en polvo y cloro), cubrebocas y gel antibacteriala</li>
							<li>Láminas de cartón y zinc</li>
							<li>Leche en polvo</li>
							<li>Pañales para bebé</li>
							<li>Ropa y zapatos en buen estado</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="footerblue d-flex flex-column text-center">
				<span className="whitetxt">O llevar al centro de acopio DIF. Av. Alemán #355, colonia Itzimná de 8:00 a 17:00 hrs.</span>
				
				
			<div className="triangleb"></div>
			</div>
		</Modal>);
	}	
}

export default ModalOpening;
