import React, { useState } from 'react';
import { translate } from 'react-translate';
import { formatPhone, validEmail } from '../config/utils';
import { categoryList } from '../config/const';

import Input from '../components/form/Input';
import Select from '../components/form/Select';
import TextArea from '../components/form/TextArea';
import Button from '../components/form/Button';
import InputMap from '../components/form/InputMap';
import Checkboxes from '../components/form/Checkboxes';
import { connect } from 'react-redux';
import { createReport } from '../redux/actions';
import ModalReport from '../components/modal/ModalReport';

const NeedSupport = (props) => {
	const { t } = props;
	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	const [category, setCategory] = useState('0');
	const [articles, setArticles] = useState([{ title: '' }]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState({ error: false, message: '' });
	const [folio, setFolio] = useState('');
	const [address, setAddress] = useState(false);
	const [whyfired, setWhyfired] = useState('');
	const [visibleModal, setVisibleModal] = useState(false);
	const [kindPerson, setKindPerson] = useState('0');
	const [CURP, setCURP] = useState('');
	const [businessName, setBusinessName] = useState('');
	const updateArticle = (index, key, value) => {
		let newData = [...articles][index];
		newData[key] = value;
		setArticles([
			...articles.slice(0, index),
			newData,
			...articles.slice(index + 1),
		]);
	};

	const _reset = () => {
		setName('');
		setPhone('');
		setEmail('');
		setKindPerson('0');
		setCategory('0');
		setArticles([{ title: '' }]);
		setWhyfired('');
		setCURP('');
		setBusinessName('');
		setAddress(false);
	};

	const addArticle = (index) => setArticles([...articles, { title: '' }]);

	const removeItem = (index) =>
		setArticles([...articles.slice(0, index), ...articles.slice(index + 1)]);

	const send = () => {
		setLoading(true);
		setError({
			error: false,
			message: '',
		});
		props
			.createReport(
				{
					whyfired,
					name,
					phone,
					email,
					category,
					articles,
					address,
					businessName,
					kindPerson,
					CURP,
				},
				'needsupport'
			)
			.then((report) => {
				setLoading(false);
				_reset();
				setFolio(report.folio);
				setVisibleModal(true);
			})
			.catch((err) => {
				setError({
					error: true,
					message: err.message,
				});
				setLoading(false);
			});
	};

	return (
		<div className="container pt-5">
			<div className="row pt-3">
				<div className="col col-12 col-sm-8 offset-sm-2">
					<div className="icon-box">
						<img
							className="el-center"
							src="/assets/images/icono-recibir-especie.svg"
						/>
					</div>
					<h3 className="text-center pink-color mt-3 mb-0 pt-0 pb-0">
						¿NECESITAS AYUDA?
					</h3>
					<h2 className="text-center pt-0 mt-0">NECESITO APOYO</h2>
				</div>
				<div className="col col-12 col-sm-10 offset-sm-1">
					<p className="text-center">
						Estamos trabajando arduamente para lograr llegar a la mayor cantidad
						de hogares yucatecos por medio de nuestras organizaciones aliadas;
						llena el siguiente formulario y con gusto te contactaremos para
						coordinar la entrega de ayuda.
					</p>
				</div>
			</div>
			<div className="row pt-3">
				<div className="col col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
					<div className="box-shadow">
						<Select
							name="type"
							value={kindPerson}
							onChange={setKindPerson}
							hideDefault={true}
							options={[
								{
									name: 'Selecciona',
									disabled: true,
									value: '0',
								},
								{
									name: 'Persona Fisica',
									value: 'fisica',
								},
								{
									name: 'Persona moral',
									value: 'moral',
								},
							]}
						/>
						<Select
							name="category"
							value={category}
							onChange={setCategory}
							hideDefault={true}
							options={[
								{
									name: 'Selecciona',
									disabled: true,
									value: '0',
								},
								...categoryList,
							]}
						/>
						<InputMap name="location" onChange={setAddress} />
						{articles.map((item, index) => (
							<div key={index} className="repeat-item">
								{articles.length > 1 ? (
									<button onClick={() => removeItem(index)}>
										<i className="material-icons">remove</i>
									</button>
								) : null}
								<TextArea
									placeholder="detailsArticle"
									name="needarticle"
									className="mb-4"
									value={item.title}
									onChange={(article) => updateArticle(index, 'title', article)}
								/>
							</div>
						))}
						<a className="mb-4 add-href" onClick={addArticle}>
							<i className="material-icons">add</i>
							<span>{t('addArticle')}</span>
						</a>
						{kindPerson === 'moral' ? (
							<Input
								name="businessName"
								value={businessName}
								onChange={setBusinessName}
							/>
						) : null}
						<Input
							placeholder="detailname"
							name={kindPerson === 'moral' ? 'legalname' : 'name'}
							value={name}
							onChange={setName}
						/>
						{kindPerson === 'fisica' ? (
							<Input
								name="curp"
								value={CURP}
								onChange={(value) => setCURP(value.toUpperCase())}
							/>
						) : null}
						<Input
							maxLength={14}
							type="tel"
							name="phone"
							value={formatPhone(phone)}
							onChange={setPhone}
							placeholder="detailphone"
						/>
						<Input
							isInvalid={email && !validEmail(email) ? true : false}
							type="email"
							name="email"
							value={email}
							optional={true}
							onChange={setEmail}
						/>
						{kindPerson === 'fisica' ? (
							<Checkboxes
								value={whyfired}
								name="whyfired"
								onChange={setWhyfired}
								options={[
									{
										name: 'Fui despedido por la contigencia',
										value: 'despedido',
									},
									{
										name: 'Mi sueldo fue reducido',
										value: 'reduccion-de-sueldo',
									},
									{
										name: 'No encuentro trabajo',
										value: 'no hay trabajo',
									},
									{
										name: 'En aislamiento por COVID-19',
										value: 'aislamiento',
									},
								]}
							/>
						) : null}
						 
						<Button
							disabled={loading}
							className="el-center"
							onClick={send}
							className="el-center"
							name={loading ? 'loading' : 'send'}
						/>
						<div className="col-12 mt-4">
							{error.error ? (
								<div className="error-message">{error.message}</div>
							) : null}
						</div>
					</div>
				</div>
			</div>
			<ModalReport
				visible={visibleModal}
				onClose={() => setVisibleModal(false)}
				folio={folio}
			/>
		</div>
	);
};

export default connect(null, { createReport })(
	translate('needsupport')(NeedSupport)
);
