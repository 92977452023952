import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { translate } from 'react-translate';
import { getAddressGoogle } from '../../redux/actions';

const InputMap = (props) => {
	const {
		t,
		name,
		icon = false,
		type = 'text',
		onChange,
		required = false,
		disabled,
		className = '',
		placeholder = 'whereAreService',
		value = {
			name: '',
			coordinates: [-89.62674044863282, 20.97043402391541],
			lat: false,
			lng: false,
			zoom: 11,
		},
		marker = '',
	} = props;
	let ref = useRef();
	let searchBoxRef = useRef();
	let map = false;
	const [focus, setFocus] = useState(false);
	const [locationLoading, setLocationLoading] = useState(false);
	let isFocus = classNames(
		'form-control column inputMap',
		{
			focus: focus,
			active: true,
			noIcon: !icon,
		},
		className
	);

	const getAddress = (data, type) => {
		const { long_name = 'N/A' } =
			data.address_components.find((i) => i.types.indexOf(type) !== -1) || {};
		return long_name;
	};
	const changeData = (GeoData = false) => {
		let center = map.getCenter();
		let data = {
			lat: center.lat(),
			lng: center.lng(),
			zoom: map.getZoom(),
			name: GeoData.formatted_address,
			address: {},
		};

		if (!GeoData)
			getAddressGoogle(data.lat, data.lng)
				.then((response) => {
					data.locality = getAddress(response, 'locality');
					data.sublocality = getAddress(response, 'sublocality');
					data.country = getAddress(response, 'country');
					data.postal_code = getAddress(response, 'postal_code');
					data.state = getAddress(response, 'administrative_area_level_1');

					searchBoxRef.current.value = response.formatted_address;
					data.name = response.formatted_address;
					if (onChange) onChange(data);
				})
				.catch((err) => console.log(err));
		else if (GeoData && onChange) {
			data.locality = getAddress(GeoData, 'locality');
			data.sublocality = getAddress(GeoData, 'sublocality');
			data.country = getAddress(GeoData, 'country');
			data.postal_code = getAddress(GeoData, 'postal_code');
			data.state = getAddress(GeoData, 'administrative_area_level_1');

			onChange(data);
		}
	};
	const onLoad = () => {
		if (!map) {
			searchBoxRef.current.value = value.name;
			map = new window.google.maps.Map(ref.current, {
				center: {
					lat: value.lat || value.coordinates[1],
					lng: value.lng || value.coordinates[0],
				},
				zoom: value.zoom || 14,
				disableDefaultUI: true,
				zoomControl: true,
				styles: [
					{
						featureType: 'all',
						stylers: [
							{
								saturation: 0,
							},
							{
								hue: '#e7ecf0',
							},
						],
					},
					{
						featureType: 'road',
						stylers: [
							{
								saturation: -70,
							},
						],
					},
					{
						featureType: 'transit',
						stylers: [
							{
								visibility: 'off',
							},
						],
					},
					{
						featureType: 'poi',
						stylers: [
							{
								visibility: 'off',
							},
						],
					},
					{
						featureType: 'water',
						stylers: [
							{
								visibility: 'simplified',
							},
							{
								saturation: -60,
							},
						],
					},
				],
			});

			map.addListener('dragend', () => changeData());
			//map.addListener('zoom_changed', () => changeData());

			let searchBox = new window.google.maps.places.SearchBox(
				searchBoxRef.current
			);

			searchBox.addListener('places_changed', () => {
				var places = searchBox.getPlaces();
				if (places.length) {
					const [first] = places;
					map.setCenter({
						lat: first.geometry.location.lat(),
						lng: first.geometry.location.lng(),
					});
					map.setZoom(17);
					changeData(first);
				}
			});
			window.geocoder = new window.google.maps.Geocoder();
		}
	};

	const useMyLocation = () => {
		setLocationLoading(true);
		navigator.geolocation.getCurrentPosition(
			({ coords }) => {
				if (map) {
					map.setCenter({
						lat: coords.latitude,
						lng: coords.longitude,
					});
					map.setZoom(17);
					changeData();
					setLocationLoading(false);
				} else setLocationLoading(false);
			},
			(err) => {
				setLocationLoading(false);
				console.log(err);
			}
		);
	};

	useEffect(() => {
		if (!window.google) {
			const script = document.createElement('script');
			script.type = 'text/javascript';
			script.src =
				'https://maps.google.com/maps/api/js?key=AIzaSyARyonDUafm-32Y6og3Qx-8bUuoQ9C5rjg&libraries=places&language=es&region=MX';
			const headScript = document.getElementsByTagName('script')[0];
			headScript.parentNode.insertBefore(script, headScript);
			script.addEventListener('load', onLoad);
			return () => script.removeEventListener('load', onLoad);
		} else onLoad();
	}, []);

	return (
		<div>
			<div className={isFocus}>
				{icon ? (
					<div className="icon">
						<i className="material-icons">{icon}</i>
					</div>
				) : null}
				<div className="form-content">
					<label style={{ left: 10 }}>
						{t(name)}
						{required ? <sup>*</sup> : null}
					</label>
					<input
						ref={searchBoxRef}
						required={required}
						autoComplete="off"
						onBlur={(e) => setFocus(false)}
						onFocus={(e) => setFocus(true)}
						type={type}
						disabled={disabled}
						placeholder={t(placeholder)}
					/>
				</div>
			</div>
			<div className="form-map-wrapper">
				{navigator.geolocation ? (
					<button onClick={useMyLocation} className="usemylocation">
						<i className="material-icons">gps_fixed</i>
						{t(locationLoading ? 'search' : 'usemylocation')}
					</button>
				) : null}
				<span className="description">{t('mapdescription')}</span>
				<div className={classNames('form-map', marker)} ref={ref}></div>
			</div>
		</div>
	);
};

export default translate('form')(InputMap);
