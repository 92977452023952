import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { findDonations } from '../../redux/actions';
import { PERPAGE, statusList, API } from '../../config/const';
import Pagination from '../../components/pagination/';
import { currency } from '../../config/utils';
import moment from 'moment';
import Select from '../../components/form/Select';
import { DateRangePicker } from 'react-dates';

const Donations = (props) => {
	const { t, $stateParams, $transition$, rol } = props;
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [total, setTotal] = useState(0);
	const [organization, setOrganization] = useState($stateParams.organization);
	const [page, setPage] = useState($stateParams.page || 1);
	const [startDate, setStartDate] = useState(
		moment($stateParams.startDate, 'DD-MM-YYYY') || moment().startOf('month')
	);
	const [endDate, setEndDate] = useState(
		moment($stateParams.endDate, 'DD-MM-YYYY') || moment().endOf('month')
	);
	const [focusedInput, setFocusedInput] = useState(null);
	useEffect(() => {
		getData();
	}, []);

	useEffect(() => changeRoute(), [page, organization, startDate, endDate]);

	const changeRoute = (reset = false) => {
		let params = {
			page,
			organization,
			startDate: moment(startDate).format('DD-MM-YYYY'),
			endDate: moment(endDate).format('DD-MM-YYYY'),
		};

		$transition$.router.stateService.go('admin.donate', params);
	};

	const getPagination = () => {
		if (total > 10)
			return (
				<div className="row mt-4 mb-4">
					<div className="col col-12 col-sm-10 offset-sm-1">
						<Pagination
							onChange={(page) => setPage(page)}
							total={total}
							perPage={PERPAGE}
							page={page}
						/>
					</div>
				</div>
			);
		return false;
	};

	const getData = (reload = false) => {
		if (!reload) setLoading(true);
		props
			.findDonations({
				page,
				organization,
				startDate: moment(startDate).format('DD-MM-YYYY'),
				endDate: moment(endDate).format('DD-MM-YYYY'),
			})
			.then(({ data, total }) => {
				setData(data);
				setTotal(total);
				setLoading(false);
			})
			.catch((err) => {});
	};

	return (
		<div className="container">
			<div className="row">
				<div className="col-12">
					<h3>DONATIVOS RECIBIDOS</h3>
				</div>
				<div className="col col-12" style={{ textAlign: 'right' }}>
					<a
						href={`${API}donation/donwload?authorization=${props.token}`}
						className="link"
					>
						Descargar datos
					</a>
				</div>
				<div className="col col-12 mb-3">
					<div className="row">
						{rol === 'admin' ? (
							<div className="col col-12 col-lg-4">
								<Select
									hideDefault={true}
									name="organization"
									value={organization}
									onChange={setOrganization}
									options={[
										{
											name: 'Todos',
											value: '0',
										},
										{
											name: 'Banco de alimentos de Merida AC',
											value: 'bamx',
										},
										{
											name: 'Fundación bepensa',
											value: 'bepensa',
										},
										{
											name: 'Fundación por la salud',
											value: 'porlasalud',
										},
									]}
								/>
							</div>
						) : null}
						 
						<div className="col col-12 col-lg-4">
							<DateRangePicker
								hideKeyboardShortcutsPanel={true}
								isOutsideRange={() => {}}
								enableOutsideDays={true}
								keepOpenOnDateSelect={true}
								startDate={startDate}
								startDateId="start_date"
								endDate={endDate}
								endDateId="endDate"
								onDatesChange={({ startDate, endDate }) => {
									setStartDate(startDate);
									setEndDate(endDate);
								}}
								focusedInput={focusedInput}
								onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
							/>
						</div>
					</div>
				</div>
				<div className="col-12">
					{data.map((item, index) => (
						<a className="item-list" key={item._id}>
							<h3>${currency(item.amount)}</h3>
							<div className="description">
								<span>{moment(item.createdAt).calendar()}</span>
								<span>{item.organization}</span>
							</div>
						</a>
					))}
				</div>
				<div className="col-12">{getPagination()}</div>
			</div>
		</div>
	);
};

const MapStateToProps = ({ token, user }) => {
	return {
		rol: user.rol || 'invited',
		token,
	};
};

export default connect(MapStateToProps, { findDonations })(Donations);
