import React from 'react';
import { findCategory } from '../config/const';
import { UISref } from '@uirouter/react';
const ListItem = (props) => {
	const { data = {} } = props;
	return (
		<UISref to="admin.detail" params={{ id: data._id }}>
			<a className="item-list">
				<h3>{data.name}</h3>
				<div className="description">
					<span>#{data.folio}</span>
					<span>{findCategory(data.category)}</span>
					<span>{data.phone}</span>
					<span>{data.address.name}</span>
				</div>
			</a>
		</UISref>
	);
};

export default ListItem;
