import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react';
import { cutImage, AWS } from '../config/const';
import { Remarkable } from 'remarkable';
var md = new Remarkable();

const HistoryItem = (props) => {
	const { data } = props;
	const [lightboxController, setLightboxController] = useState({
		toggler: false,
		slide: 1,
	});

	const openLightboxOnSlide = (number) => {
		setLightboxController({
			toggler: !lightboxController.toggler,
			slide: number,
		});
	};

	return (
		<div className="col col-12 col-sm-6 col-lg-4">
			<div className="thumbs-gallery">
				<p
					dangerouslySetInnerHTML={{ __html: md.render(data.description) }}
				></p>
				<div className="thumbs-images">
					{data.files.map((i, index) => (
						<div
							onClick={() => openLightboxOnSlide(index + 1)}
							className="img-preview"
							style={{ backgroundImage: `url(${cutImage(200, 150, i.fd)})` }}
						></div>
					))}
				</div>
				<button onClick={() => openLightboxOnSlide(1)}>Abrir Galería</button>
			</div>
			<FsLightbox
				thumbs={data.files.map((o) => cutImage(200, 200, o.fd))}
				toggler={lightboxController.toggler}
				slide={lightboxController.slide}
				sources={data.files.map((o) => `${AWS}${o.fd}`)}
				captions={data.files.map((o) => (
					<p
						dangerouslySetInnerHTML={{ __html: md.render(data.description) }}
					></p>
				))}
			/>
		</div>
	);
};

export default HistoryItem;
