export const API =
	process.env.NODE_ENV === 'development'
		? 'https://api.solidario.virk.io/v1.0/'
		: 'https://api.solidario.virk.io/v1.0/';

export const AWS = 'https://virk.s3-us-west-1.amazonaws.com/';

export const cutImage = (width = 150, height = 150, file) => {
	return `${AWS}${file}`;
};
export const categoryList = [
	{
		name: 'Alimentos perecederos',
		value: 'alimentos-perecederos',
	},
	{
		name: 'Alimentos no perecederos',
		value: 'alimentos-no-perecederos',
	},
	{
		name: 'Medicamentos e insumos médicos',
		value: 'medicamentos-e-insumos-medicos',
	},
	{
		name: 'Bienes y servicios diversos',
		value: 'bienes-y-servicios-diversos',
	},
];

export const statusList = [
	{
		name: 'Pendiente',
		value: 'pending',
	},
	{
		name: 'En proceso',
		value: 'in-process',
	},
	{
		name: 'Cerrado con solución',
		value: 'closeSolution',
	},
	{
		name: 'Cerrado sin solución',
		value: 'closeNotSolution',
	},
];

export const findCategory = (value = '') => {
	let { name = 'N/A' } = categoryList.find((i) => i.value === value) || {};
	return name;
};
export const customStyles = {
	content: {
		bottom: 'initial',
		borderRadius: 5,
		height: 'auto',
		padding: 15,
		minHeight: 100,
		border: '0px',
		width: '100%',
		maxWidth: '520px',
		margin: '0px auto',
		boxShadow:
			'0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12), 0 3px 1px -2px rgba(0,0,0,.2)',
	},
	overlay: {
		background: 'rgba(0,0,0,0.5)',
		zIndex: 2,
	},
};

export const PERPAGE = 10;
