import React from 'react';
import { UISref, UISrefActive } from '@uirouter/react';

const Header = (props) => {
	return (
		<header>
			<div className="pleca">
				<div className="container">
					<div className="row">
						<div className="col d-flex">
							<a href="mailto:yucatan.solidario@gmail.com.">
								<i className="material-icons">email</i>
								<span>Escríbenos:</span> yucatan.solidario@gmail.com
							</a>
						</div>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col">
						<div className="header-content">
							<UISref to="layout.home">
								<a>
									<img src="/assets/images/favicon-01.svg" className="logo" />
								</a>
							</UISref>
							<ul className="nav-header">
								<li>
									<UISrefActive class="active">
										<UISref to="layout.orgs">
											<a>ORGANIZACIONES</a>
										</UISref>
									</UISrefActive>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
