import React, { useState, useEffect, createRef} from 'react';
import FsLightbox from 'fslightbox-react';
import HistoryItem from '../components/HistoryItem';

import { connect } from 'react-redux';
import { findpublicGallery } from '../redux/actions';
import { cutImage, AWS } from '../config/const';
import { Remarkable } from 'remarkable';

var md = new Remarkable();

const Historias = (props) => {
	const scrollDiv = createRef();

	const scrollSmoothHandler = () => {
		scrollDiv.current.scrollIntoView({ behavior: "smooth" });
	};

	const [data, setData] = useState([]);
	const [lightboxController, setLightboxController] = useState({
		toggler: false,
		slide: 1,
	});

	const [lightboxVideoController, setLightboxVideoController] = useState({
		toggler: false,
		slide: 1,
	});

	const openLightboxOnSlide = (number) => {
		setLightboxController({
			toggler: !lightboxController.toggler,
			slide: number,
		});
	};

	const openLightboxVideoOnSlide = (number = 1) => {
		setLightboxVideoController({
			toggler: !lightboxVideoController.toggler,
			slide: number,
		});
	};

	useEffect(() => {
		props
			.findpublicGallery()
			.then((data) => {
				setData(data);
			})
			.catch((err) => console.log(err));
	}, []);

	const videos = data.filter((i) => i.type === 'video');
	const images = data.filter((i) => i.type === 'imagen');
	const limit = 10;
	const videosd = videos;
    const datavideo = videosd.slice(0, limit);
	return (
		<div className="container pt-5">
			<div className="row pt-3">
				<div className="col col-12 col-sm-8 offset-sm-2">
					<div className="icon-box">
						<img
							className="el-center"
							src="/assets/images/icono-recibir-especie.svg"
						/>
					</div>
					<h3 className="text-center pink-color mt-3 mb-0 pt-0 pb-0">
						SEGUIMOS TRABAJANDO
					</h3>
					<h2 className="text-center pt-0 mt-0">HISTORIAS DE ÉXITO</h2>
				</div>
				<div className="col col-12 col-sm-10 offset-sm-1">
					<p className="text-center">
						Estamos trabajando arduamente para lograr llegar a la mayor cantidad
						de hogares yucatecos por medio de nuestras organizaciones aliadas y
						voluntarios que ponen su mayor esfuerzo por y para Yucatán.
					</p>
				</div>
				<div className="col col-12">
					<h2 className="ligth">GALERÍA DE IMÁGENES</h2>
				</div>
				<div className="col col-12 col-sm-9">
					<div className="row">
						{images.map((item, index) => (
							<HistoryItem data={item} key={item._id} />
						))}
					</div>
				</div>
				<div className="col col-12 col-sm-3 d-none d-sm-block">
					<div className="row graybg">
						<div className="col col-12">
							<h2 className="ligth">VIDEOS</h2>
						</div>
						{datavideo.map((item, index) => (
							<div
								key={item._id}
								onClick={() => openLightboxVideoOnSlide(index + 1)}
								className="col col-12 pointer col-sm-12"
							>
								<div className="thumbs-videol">
									<div className="thumbs-imgl">
										<img src={cutImage(250, 130, item.files[0].fd)} />
										<div className="play-icon">
											<i className="material-icons">play_arrow</i>
										</div>
									</div>
									<p
										className="caption"
										dangerouslySetInnerHTML={{
											__html: md.render(item.description),
										}}
									/>
								</div>
							</div>
						))}
						<div className="btn-abehai">
							<a className="abehai" onClick={scrollSmoothHandler}>Ver más videos</a>
						</div>
					</div>
				</div>
				<div className="col col-12" ref={scrollDiv}>
					<h2 className="ligth">VIDEOS</h2>
				</div>

				<div className="col col-12">
					<div className="row">
						{videos.map((item, index) => (
							<div
								key={item._id}
								onClick={() => openLightboxVideoOnSlide(index + 1)}
								className="col col-12 pointer col-sm-6 col-md-6 col-lg-4"
							>
								<div className="thumbs-video">
									<div className="thumbs-img">
										<img src={cutImage(250, 130, item.files[0].fd)} />
										<div className="play-icon">
											<i className="material-icons">play_arrow</i>
										</div>
									</div>
									<p
										className="caption"
										dangerouslySetInnerHTML={{
											__html: md.render(item.description),
										}}
									/>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
			{videos.length > 0 ? (
				<FsLightbox
					toggler={lightboxVideoController.toggler}
					slide={lightboxVideoController.slide}
					sources={videos.map(({ files }) => `${AWS}${files[1].fd}`)}
					captions={videos.map((o) => (
						<p
							dangerouslySetInnerHTML={{ __html: md.render(o.description) }}
						></p>
					))}
				/>
			) : null}
		</div>
	);
};

export default connect(null, { findpublicGallery })(Historias);
