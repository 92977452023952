import React from 'react';
import { UIView } from '@uirouter/react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Layout = (props) => {
	return (
		<div>
			<Header />
			<div className="wrapper">
				<UIView />
			</div>
			<Footer />
		</div>
	);
};

export default Layout;
