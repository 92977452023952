import React, { useState } from 'react';
import Button from './form/Button';
import { translate } from 'react-translate';
const Search = (props) => {
	const { onChange, placeholder = 'default', t } = props;
	const [value, setValue] = useState(props.value || '');

	const onKeyPress = (e) => {
		if (e.charCode === 13 && onChange) onChange(e.target.value);
		if (e.target.value.length === 0 || e.target.value === '')
			onChange(e.target.value);
	};
	return (
		<div className="search">
			<input
				value={value}
				onKeyPress={onKeyPress}
				placeholder={t(placeholder)}
				onChange={(e) => setValue(e.target.value)}
			/>
		</div>
	);
};

export default translate('search')(Search);
