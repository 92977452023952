import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { findOneGallery, removeGallery } from '../../redux/actions';
import { Remarkable } from 'remarkable';
import { cutImage, AWS } from '../../config/const';
import ModalConfirm from '../../components/modal/ModalConfirm';
var md = new Remarkable();

const GallerySingle = (props) => {
	const { id } = props;
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({ files: [] });
	const [visibleRemove, setVisibleRemove] = useState(false);

	useEffect(() => {
		setLoading(true);
		getData();
	}, []);

	const getData = () => {
		props
			.findOneGallery(id)
			.then((data) => {
				setData(data);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const onCloseRemove = (deleted = false) => {
		setVisibleRemove(false);
		if (deleted) props.$transition$.router.stateService.go('admin.gallery');
	};

	return (
		<div className="container">
			<div className="row pt-5">
				<div className="col col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
					<div className="box-shadow single">
						<div className="d-flex justify-content-between align-items-center">
							<span></span>
							<div className="d-flex align-items-center justify-conten-end">
								<button onClick={() => setVisibleRemove(true)}>
									<i className="material-icons">delete</i>
								</button>
							</div>
						</div>
						<div className="form-value  mt-4">
							<label>Tipo:</label>
							<p>{data.type}</p>
						</div>
						<div className="form-value  mt-2">
							<label>Descripción:</label>
							<p
								dangerouslySetInnerHTML={{
									__html: md.render(data.description),
								}}
							/>
						</div>
						<div className="form-value  mt-2">
							<label className="mb-2">Documentos:</label>
							{data.files.map((item) => (
								<a
									className="mb-2"
									href={`${AWS}${item.fd}`}
									target="_new"
									key={item._id}
								>
									{item.filename}
								</a>
							))}
						</div>
					</div>
				</div>
			</div>
			<ModalConfirm
				action={props.removeGallery}
				params={{ id: data._id }}
				visible={visibleRemove}
				onClose={(deleted) => onCloseRemove(deleted)}
			/>
		</div>
	);
};

const MapStateToProps = (state, { $stateParams }) => {
	return {
		id: $stateParams.id,
	};
};

export default connect(MapStateToProps, { findOneGallery, removeGallery })(
	GallerySingle
);
