import React from 'react';
import classNames from 'classnames';
import { translate } from 'react-translate';

const Badge = (props) => {
	const { status = 'pending', t } = props;
	let container = classNames('badge', status);
	return <div className={container}>{t(status)}</div>;
};

export default translate('badge')(Badge);
