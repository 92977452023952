export const formatPhone = (value = '') => {
	/*
	 ** quitamos los parenresis
	 ** quitamos espacios
	 ** quitamos letras
	 ** añadimos formato
	 **/

	return (value || '')
		.replace(/[a-zA-Z]/g, '')
		.replace(/\(|\)/g, '')
		.replace(/-/gi, '')
		.replace(/\W/gi, '')
		.replace(/\b(.{3})(.{3})(.{4})\b/gi, '($1) $2 $3');
};

export const validEmail = (email) => {
	return /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(
		email
	);
};

export const responseError = (reject, dispatch, err) => {
	if (err.response && err.response.data && err.response.data.status === 403) {
		dispatch({
			type: 'CLEAR_TOKEN',
		});
		setTimeout(() => {
			window.location.href = '/login?message=expiresession';
		}, 100);
	} else if (
		err.response &&
		err.response.status === 404 &&
		!err.response.data.message
	)
		return reject({
			error: true,
			message:
				'No tenemos comunicación con el servidor por favor intenta más tarde',
			status: 500,
		});
	else if (err && err.response && err.response.data)
		return reject(err.response.data);
	else
		return reject({
			error: true,
			message:
				'No tenemos comunicación con el servidor por favor intenta más tarde',
			status: 500,
		});
};

export const generatePagination = (page, pages) => {
	var current = page,
		last = pages,
		delta = 2,
		left = current - delta,
		right = current + delta + 1,
		range = [],
		rangeWithDots = [],
		l;

	for (let i = 1; i <= last; i++) {
		if (i == 1 || i == last || (i >= left && i < right)) {
			range.push({ page: i });
		}
	}

	for (let i of range) {
		if (l) {
			if (i.page - l.page === 2) {
				rangeWithDots.push({ page: l.page + 1 });
			} else if (i.page - l.page !== 1) {
				rangeWithDots.push({ page: l.page + 1, isDots: true });
			}
		}
		rangeWithDots.push({ page: i.page });
		l = i;
	}

	return rangeWithDots;
};

export const currency = (total) => {
	return parseFloat(total)
		.toFixed(2)
		.replace(/\d(?=(\d{3})+\.)/g, '$&,');
};
