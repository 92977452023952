import React, { useState } from 'react';
import Modal from 'react-modal';
import { customStyles } from '../../config/const';
import { translate } from 'react-translate';
import Button from '../form/Button';

const ModalIcay = (props) => {
	const { visible = false, onClose, t } = props;

	const closeModal = (reload = false) => {
		if (onClose) onClose(reload);
	};

	return (
		<Modal
			isOpen={visible ? true : false}
			onRequestClose={() => closeModal(false)}
			style={customStyles}
			className="modal"
			contentLabel=""
			ariaHideApp={false}
		>
			<button onClick={() => closeModal(false)} className="modal-close">
				<i className="material-icons">close</i>
			</button>
			<div className="modal-header mt-4"></div>
			<div className="modal-body mt-4 mb-4">
				<h1 className="mt-5">ICAY (INICIATIVA CIUDADANA DE APOYO A YUCATÁN)</h1>
				<p className="poppins">
					<b>BANCO</b>: Citibanamex
					<br />
					<b>BENEFICIARIO</b>: Arquidiócesis de Yucatán A.R.
					<br />
					<b>CUENTA</b>: 112303152
					<br />
					<b>CLAVE</b>: 00291070 1123031521
					<br />
					<b>CLAVE SWIFT</b>: BNMXMXMM
				</p>
			</div>
		</Modal>
	);
};

export default translate('modal')(ModalIcay);
