import React from 'react';
import PaginationItem from './PaginationItem';
import { generatePagination } from '../../config/utils';

export const Pagination = (props) => {
	const { page = 1, total = 0, perPage = 10, onChange } = props;
	const pages = Math.ceil(total / perPage);

	const onChangePage = (page) => {
		if (onChange) onChange(page);
	};

	const getPages = () => {
		var items = generatePagination(page, pages);
		return items.map((item) => (
			<PaginationItem
				key={item.page}
				onClick={() => onChangePage(item.page)}
				active={item.page === parseInt(page)}
				value={item.isDots ? '...' : item.page}
			/>
		));
	};

	return (
		<div className="pagination">
			<PaginationItem
				onClick={() => onChange(page - 1)}
				disabled={parseInt(page) === 1}
				icon="keyboard_arrow_left"
			/>
			{getPages()}
			<PaginationItem
				onClick={() => onChange(page + 1)}
				icon="keyboard_arrow_right"
				disabled={parseInt(page) === pages}
			/>
		</div>
	);
};

export default Pagination;
