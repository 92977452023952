import axios from 'axios';
import { API } from '../../config/const';
import { responseError } from '../../config/utils';
import { SET_TOKEN, SET_USER } from '../types';

export const login = ({ email = '', password = '' }) => (
	dispatch,
	getState
) => {
	return new Promise((resolve, reject) => {
		axios({
			method: 'POST',
			url: `${API}account/login`,
			data: {
				email,
				password,
			},
		})
			.then(({ data }) => {
				dispatch({
					type: SET_USER,
					user: data.user,
				});
				dispatch({
					type: SET_TOKEN,
					token: data.token,
				});
				return resolve(data);
			})
			.catch((err) => responseError(reject, dispatch, err));
	});
};
