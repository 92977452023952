import React from 'react';
import classNames from 'classnames';
const CheckBox = (props) => {
	const { active = false, name = '', isRadio = false, onClick } = props;
	const container = classNames('wrapper-checkbox', {
		isRadio,
		active,
	});
	return (
		<div className={container} onClick={() => (onClick ? onClick() : null)}>
			<div className="checkbox">
				{active ? <i className="material-icons">check</i> : null}
			</div>
			<span>{name}</span>
		</div>
	);
};

export default CheckBox;
