import React, { useState } from 'react';
import { translate } from 'react-translate';
import classNames from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';

const TextArea = (props) => {
	const {
		t,
		name,
		icon = false,
		onChange,
		value = '',
		required = false,
		maxLength,
		disabled,
		className = '',
		placeholder = false,
	} = props;

	const [focus, setFocus] = useState(false);

	let isFocus = classNames(
		'form-control textarea',
		{
			focus,
			active: value ? true : focus,
			noIcon: !icon,
		},
		className
	);

	const changeValue = ({ target }) => {
		if (onChange) onChange(target.value);
	};

	return (
		<div className={isFocus}>
			{icon ? (
				<div className="icon">
					<i className="material-icons">{icon}</i>
				</div>
			) : null}
			<div className="form-content">
				<label>
					{t(name)}
					{required ? <sup>*</sup> : null}
				</label>
				<TextareaAutosize
					placeholder={placeholder ? t(placeholder) : null}
					maxLength={maxLength}
					required={required}
					autoComplete="off"
					onBlur={(e) => setFocus(false)}
					onFocus={(e) => setFocus(true)}
					onChange={changeValue}
					value={value}
					disabled={disabled}
				/>
			</div>
		</div>
	);
};

export default translate('form')(TextArea);
