import React, { useState, useEffect } from 'react';
import { findReport, removeReport } from '../../redux/actions';
import { connect } from 'react-redux';
import Badge from '../../components/Badge';
import moment from 'moment';
import { findCategory } from '../../config/const';
import ModalConfirm from '../../components/modal/ModalConfirm';
import ModalStatus from '../../components/modal/ModalStatus';

const Detail = (props) => {
	const { id } = props;
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({ articles: [], address: {} });
	const [visibleRemove, setVisibleRemove] = useState(false);
	const [visibleEdit, setVisibleEdit] = useState(false);
	useEffect(() => {
		setLoading(true);
		getData();
	}, []);

	const getData = () => {
		props
			.findReport(id)
			.then((data) => {
				setData(data);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	};

	const closeEdit = (reload = false) => {
		setVisibleEdit(false);
		console.log(reload);
		if (reload) getData();
	};

	const onCloseRemove = (deleted = false) => {
		setVisibleRemove(false);
		if (deleted)
			props.$transition$.router.stateService.go('admin.supportreceived');
	};
	if (loading) return <div>Cargando...</div>;
	return (
		<div className="container">
			<div className="row pt-3">
				<div className="col col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
					<div className="box-shadow single">
						<div className="d-flex justify-content-between align-items-center">
							<Badge status={data.status} />
							<div className="d-flex align-items-center justify-conten-end">
								<button
									onClick={() => setVisibleEdit(true)}
									style={{ marginRight: 5 }}
								>
									<i className="material-icons">edit</i>
								</button>
								<button onClick={() => setVisibleRemove(true)}>
									<i className="material-icons">delete</i>
								</button>
							</div>
						</div>
						<h3>FOLIO: #{data.folio}</h3>
						<b>{moment(data.createdAt).calendar()}</b>

						<div className="form-value  mt-4">
							<label>Categoría:</label>
							<p>{findCategory(data.category)}</p>
						</div>

						<div className="form-value">
							<label>Dirección:</label>
							<p>
								{data.address.name}{' '}
								<a
									href={`https://www.google.com/maps?saddr=My+Location&daddr=${data.address.coordinates[1]},${data.address.coordinates[0]}`}
									target="new"
								>
									¿cómo llegar?
								</a>
							</p>
						</div>

						<div className="form-value">
							<label>Razon social:</label>
							<p>{data.businessName}</p>
						</div>
						<div className="form-value">
							<label>Nombre de contacto:</label>
							<p>{data.name}</p>
						</div>

						<div className="form-value">
							<label>Teléfono:</label>
							<p>{data.phone}</p>
						</div>

						<div className="form-value">
							<label>Correo:</label>
							<p>{data.email}</p>
						</div>

						<div className="form-value">
							<label>Articulos:</label>
							<ul>
								{data.articles.map((item) => (
									<li key={item._id}>{item.title}</li>
								))}
							</ul>
						</div>
						<div className="form-value">
							<label>Comentarios:</label>
							<p>{data.comments}</p>
						</div>
					</div>
				</div>
			</div>
			<ModalConfirm
				action={props.removeReport}
				params={{ id: data._id }}
				visible={visibleRemove}
				onClose={(deleted) => onCloseRemove(deleted)}
			/>
			<ModalStatus
				reportId={data._id}
				status={data.status}
				visible={visibleEdit}
				onClose={closeEdit}
			/>
		</div>
	);
};
const MapStateToProps = (state, { $stateParams }) => {
	return {
		id: $stateParams.id,
	};
};

export default connect(MapStateToProps, { findReport, removeReport })(Detail);
