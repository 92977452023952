import React from 'react';

const Organizasiones = (props) => {
	return (
		<div className="container pt-5">
			<div className="row pt-3">
				<div className="col col-12 col-sm-8 offset-sm-2">
					<div className="icon-box">
						<img
							className="el-center"
							width="100px"
							src="/assets/images/bolsa-despensa.svg"
						/>
					</div>
					<h3 className="text-center purpple-color mt-3 mb-0 pt-0 pb-0">
						YUCATÁN SOLIDARIO
					</h3>
					<h2 className="text-center pt-0 mt-0">ORGANIZACIONES ALIADAS</h2>
				</div>
				<div className="col col-12 col-sm-10 offset-sm-1">
					<p className="text-center">
						La plataforma web constituye un esfuerzo por{' '}
						<b>
							concentrar todas las iniciativas ciudadanas de apoyo con motivo de
							la contingencia sanitaria
						</b>
						, para sumar esfuerzos y trabajar de forma organizada para llegar a
						la mayor cantidad de familias yucatecas. Agradecemos el apoyo de
						quienes hacemos grande a Yucatán con solidaridad y esfuerzo en favor
						de los que más lo necesitan.
					</p>
				</div>
			</div>
			<div className="col col-12 mt-4">
				<div className="box-shadow">
					<div className="row">
						<div className="col col-12 col-sm-3">
							<img
								className="el-center img-responsive"
								src="/assets/images/Logo_bamx.png"
							/>
						</div>
						<div className="col col-12 col-sm-9">
							<h3 className="purpple-color mt-3 mb-0 pt-0 pb-0">
								ORGANIZACIÓN ALIADA
							</h3>
							<h2 className="pt-0 mt-0">BANCO DE ALIMENTOS DE MÉRIDA, A.C.</h2>

							<p>
								Somos una Asociación Civil sin fines de lucro que trabaja desde
								hace 23 años en Mérida, promovemos la participación social para
								rescatar el recurso alimentario no comercializable y con esto
								apoyamos a personas que se encuentren en situación de pobreza y
								carencia alimentaria. Pertenecemos a la red nacional de más de
								55 Bancos de Alimentos en todo el País.
							</p>
							<h3>¿A QUIENES AYUDAMOS?</h3>
							<p>
								Al día de hoy el Banco de Alimentos de Mérida, atiende a 27 mil
								personas, 50 instituciones y cuenta con 200 voluntarios. Ante la
								contingencia por el COVID 19 continuamos ofreciendo alimento a
								quien más lo necesita con los cuidados de higiene y asistencia
								social recomendados.
							</p>
							<h3>PARA REALIZAR UN DONATIVO EN EFECTIVO</h3>
							<p>
								Podemos otorgar deducible de impuestos por cada donación al
								formar parte de las organizaciones donatarias autorizadas por la
								SHCP para expedir recibos deducibles de impuestos. Para
								solicitar el tuyo, envía tu ficha de depósito o comprobante de
								transferencia bancaria y tus datos fiscales al correo{' '}
								<a href="mailto:yucatan.solidario@gmail.com">
									yucatan.solidario@gmail.com
								</a>
							</p>
							<h3>PARA TRANSFERENCIAS BANCARIAS: </h3>
							<p>
								<b>BANCO</b>: HSBC
								<br />
								<b>BENEFICIARIO</b>: Banco de alimentos de Mérida, A.C.
								<br />
								<b>CUENTA</b>: 4040495079 <br />
								<b>CLABE</b>: 021910040404950791
								<br />
								<b>Nº DE CLIENTE</b>: 13681923
								<br />
								<b>RFC</b>: BAM961115-J85
								<br />
							</p>
							<h3>CONTACTO</h3>
							<p>
								Para cualquier duda o más informes contáctanos vía email a{' '}
								<a href="mailto:procurador@bamxmerida.org.mx">
									procurador@bamxmerida.org.mx
								</a>{' '}
								o llámanos a los teléfonos 442-20-60 o 61
							</p>
							<h3>SITIO WEB</h3>
							<p>
								<a href="https://bamxmerida.wixsite.com/bamac" target="_new">
									https://bamxmerida.wixsite.com/bamac
								</a>
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className="col col-12 mt-4">
				<div className="box-shadow">
					<div className="row mt-5">
						<div className="col col-12 col-sm-3">
							<img
								className="el-center img-responsive"
								src="/assets/images/Logo_fundacionporlasalud.png"
							/>
						</div>
						<div className="col col-12 col-sm-9">
							<h3 className="purpple-color mt-3 mb-0 pt-0 pb-0">
								ORGANIZACIÓN ALIADA
							</h3>
							<h2 className="pt-0 mt-0">
								FUNDACIÓN POR LA SALUD EN YUCATÁN, A.C.
							</h2>

							<p>
								Somos una fundación sin fines de lucro, que trabajamos en pro de
								la salud de la gente más necesitada del Estado de Yucatán
								principalmente por los niños. Fundada constitutivamente desde el
								día 25 de marzo del 2004 en la ciudad de Mérida.
							</p>
							<h3>¿A QUIENES AYUDAMOS?</h3>
							<p>
								Contribuimos al mejoramiento de la salud a través del apoyo a
								personas e instituciones carentes de recursos económicos
								poniendo a su alcance las herramientas y apoyos necesarios que
								les permita brindar una atención profesional de excelencia y con
								un trato humano para el paciente y su familia.
							</p>
							<h3>PARA REALIZAR UN DONATIVO EN EFECTIVO</h3>
							<p>
								<b>EN TODO EL TERRITORIO NACIONAL:</b>
							</p>
							<p>
								<b>BANCO</b>: Santander Serfin
								<br />
								<b>BENEFICIARIO</b>: Fundación por la Salud en Yucatán A.C.
								<br />
								<b>CUENTA</b>: 92000243120 [Depósito Directo]
							</p>
							<p>
								<b>BANCO</b>: BBVA
								<br />
								<b>BENEFICIARIO</b>: Fundación por la Salud en Yucatán A.C.
								<br />
								<b>CLABE</b>: 012910001141768207
								<br />
								<b>CUENTA</b>: 0114176820
								<br />
								<b>RFC</b>: FSY04032KQ5
							</p>
							<p>
								<b>PARA TRANSFERENCIAS INTERNACIONALES:</b>
							</p>
							<p>
								<b>BANCO</b>: Santander Central Hispano New York
								<br />
								<b>BENEFICIARIO</b>: Fundación por la Salud en Yucatán A.C.
								<br />
								<b>ABA</b> 026007692
								<br />
								<b>CUENTA</b>: 100015-001
							</p>
							<h3>CONTACTO</h3>
							<p>
								Hospital Regional de Alta Especialidad de la Península de
								Yucatán, Calle 22, Reserva Territorial Propiedad Estatal, Fracc.
								Altabrisa, 97133 Mérida, Yucatán. Nuestros correos electrónicos
								son{' '}
								<a href="mailto:direccion@funsayuc.org">
									direccion@funsayuc.org
								</a>{' '}
								y{' '}
								<a href="mailto:donativos@funsayuc.org">
									donativos@funsayuc.org
								</a>
								, y por teléfono al 9427600 Ext. 51406
							</p>
							<h3>SITIO WEB</h3>
							<p>
								<a href="http://www.funsayuc.org/contacto.html" target="_new">
									http://www.funsayuc.org/contacto.html
								</a>
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className="col col-12 mt-4">
				<div className="box-shadow">
					<div className="row mt-5">
						<div className="col col-12 col-sm-3">
							<img
								className="el-center img-responsive"
								src="/assets/images/Logo_yucatanunido.png"
							/>
						</div>
						<div className="col col-12 col-sm-9">
							<h3 className="purpple-color mt-3 mb-0 pt-0 pb-0">
								ORGANIZACIÓN ALIADA
							</h3>
							<h2 className="pt-0 mt-0">YUCATAN UNIDO EN LA CONTINGENCIA</h2>

							<p>
								Somos un grupo de organizaciones de la sociedad civil
								encabezadas por los Clubes Rotarios de Yucatán, la Fundación del
								Empresariado Yucateco A.C., la Asociación de Scouts de Yucatán y
								el colectivo de Empresas Socialmente Responsables, Aliarse por
								Yucatán. Tomamos la decisión de emprender una gran cruzada para
								convocar al pueblo yucateco a sumarse en un acto comunitario de
								solidaridad.
							</p>
							<p>
								A la iniciativa se han sumado empresas como Casa Santos Lugo,
								Willys, Soriana, Oxxo, Súper Aki, Coma, Kekén, Kybernus, la
								Fundación ADO, agrupaciones civiles como Frente Cívico Familiar
								y Luciérnagas así como el Consejo Coordinador Empresarial.
							</p>
							<h3>¿A QUIENES AYUDAMOS?</h3>
							<p>
								<b>HOSPITALES</b>: Con la entrega equipos de salud e insumos
								necesarios durante sus arduas jornadas, como: cubrebocas,
								guantes no estériles, gafas o protectores faciales, batas,
								gorros, entre otros.
							</p>
							<p>
								<b>FAMILIAS MÁS NECESITADAS</b>: A través de la entrega de
								despensas provistas con productos de la canasta básica.
							</p>
							<p>
								También implementamos un esquema de recaudación de apoyos en
								efectivo o en especie entre el sector empresarial.
							</p>
							<h3>PARA REALIZAR UN DONATIVO EN EFECTIVO</h3>
							<p>
								Todo aquel donante que lo requiera se le expedirá comprobante de
								donación deducible de impuestos enviando ficha de depósito o
								comprobante de transferencia bancaria y datos fiscales al correo{' '}
								<a href="mailto:yucatan.solidario@gmail.com">
									yucatan.solidario@gmail.com
								</a>
							</p>
							<p>
								<b>BANCO</b>: BANORTE
								<br />
								<b>BENEFICIARIO</b>: FUNDACIÓN DEL EMPRESARIADO YUCATECO, AC
								<br />
								<b>CUENTA</b>: 0232202645
								<br />
								<b>CLABE</b>: 072 910 00232202645 1
							</p>

							<h3>CONTACTO</h3>
							<p>
								Fundación del Empresariado Yucateco A. C. Calle 3 No. 263 por 36
								y 38 Campestre. Teléfono al <b>944 29 81</b>
							</p>
							<h3>SITIO WEB</h3>
							<p>
								<a href="https://www.yucatanunido.org" target="_new">
									https://www.yucatanunido.org
								</a>
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className="col col-12 mt-4">
				<div className="box-shadow">
					<div className="row mt-5">
						<div className="col col-12 col-sm-3">
							<img
								className="el-center img-responsive"
								src="/assets/images/logo-bepensa.svg"
							/>
						</div>
						<div className="col col-12 col-sm-9">
							<h3 className="purpple-color mt-3 mb-0 pt-0 pb-0">
								ORGANIZACIÓN ALIADA
							</h3>
							<h2 className="pt-0 mt-0">FUNDACIÓN BEPENSA</h2>

							<p>
								Fundación Bepensa desde sus orígenes, hace más de 15 años,
								siempre ha buscado llevar el bienestar a las comunidades donde
								las empresas de Bepensa tienen presencia, es por ello que hoy,
								más que nunca es momento de permanecer seguros y tender la mano
								a quien más lo necesite.
							</p>
							<h3>¿A QUIENES AYUDAMOS?</h3>
							<p>
								Con tres líneas de acción: salud, medio ambiente y desarrollo
								social, nuestro objetivo es cubrir la mayor cantidad de personas
								posibles.
							</p>
							<p>
								El proyecto de más reciente surgimiento, se llama Regalove, una
								plataforma en línea que ofrece la posibilidad de realizar
								donativos mediante cargo a tarjeta de crédito y transferencia
								bancaria. Las asociaciones que se presentan para apoyar
								financieramente, son organizaciones que han sido apoyadas por la
								Fundación Bepensa durante muchos años, debidamente
								profesionalizadas, con resultados comprobados. El donador puede
								pedir un recibo deducible de impuestos.
							</p>

							<p>
								Las ONGs que se pueden apoyar vía Regalove son las siguientes,
								aunque cabe mencionar que se reciben solicitudes para participar
								en la plataforma frecuentemente.
							</p>
							<ul>
								<li>Albergue de la Buena Voluntad</li>
								<li>Albergue Temporal San Vicente</li>
								<li>Asilo Brunet Celaráin</li>
								<li>Banco de Alimentos de Yucatán</li>
								<li>Banco de Alimentos de los Altos de Chiapas</li>
								<li>
									Centro de Rehabilitación Integral para Adolescentes CRIA
								</li>
								<li>Ciudad Vicentina</li>
								<li>Cruz Roja</li>
								<li>Construyendo Sonrisas</li>
								<li>Cottolengo</li>
								<li>Casa para ancianos la Divina Providencia</li>
								<li>Cáritas de Yucatán</li>
								<li>Dispensario Ma Soledad</li>
								<li>Fundación para la Salud en Yucatán</li>
								<li>Grupo Kerigma</li>
							</ul>

							<h3>PARA REALIZAR UN DONATIVO EN EFECTIVO</h3>
							<p>
								Todo aquel donante que lo requiera se le expedirá comprobante de
								donación deducible de impuestos enviando ficha de depósito o
								comprobante de transferencia bancaria y datos fiscales al correo{' '}
								<a href="mailto:yucatan.solidario@gmail.com">
									yucatan.solidario@gmail.com
								</a>
							</p>
							<p>
								<b>BANCO</b>: SANTANDER
								<br />
								<b>BENEFICIARIO</b>: FUNDACIÓN BEPENSA, AC
								<br />
								<b>CUENTA</b>: 65501905415
							</p>

							<h3>CONTACTO</h3>
							<p>
								FUNDACIÓN BEPENSA
								<br />
								Lucía Cervera G Cantón
								<br />
								<a href="mailto:lcerveraca@bepensa.com">
									lcerveraca@bepensa.com
								</a>
								<br />
								Teléfono{' '}
								<a href="https://wa.me/529991638617" target="_new">
									(999)1638617
								</a>{' '}
								exclusivamente whattsapp
							</p>
							<h3>SITIO WEB</h3>
							<p>
								<a href="http://www.fundacionbepensa.org" target="_new">
									http://www.fundacionbepensa.org
								</a>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Organizasiones;
