import axios from 'axios';
import { API } from '../../config/const';
import { responseError } from '../../config/utils';
import { CLEAR_TOKEN, CHANGE_MODAL  } from '../types';
export * from './report';
export * from './account';

export const logout = () => (dispatch, getState) => {
	dispatch({
		type: CLEAR_TOKEN,
	});
};
export const changeOpening = status => (dispatch,getState) => {
	return dispatch({ type: CHANGE_MODAL, status :  status });
}

export const removeGallery = ({ id }) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		axios({
			method: 'DELETE',
			url: `${API}gallery/${id}`,
			headers: {
				Authorization: getState().token,
			},
		})
			.then(({ data }) => {
				return resolve(data);
			})
			.catch((err) => responseError(reject, dispatch, err));
	});
};
export const findOneGallery = (id) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		axios({
			method: 'GET',
			url: `${API}gallery/${id}`,
			headers: {
				Authorization: getState().token,
			},
		})
			.then(({ data }) => {
				return resolve(data);
			})
			.catch((err) => responseError(reject, dispatch, err));
	});
};

export const findGallery = (page = 1) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		axios({
			method: 'GET',
			url: `${API}gallery`,
			params: {
				page,
			},
			headers: {
				Authorization: getState().token,
			},
		})
			.then(({ data }) => {
				return resolve(data);
			})
			.catch((err) => responseError(reject, dispatch, err));
	});
};

export const findpublicGallery = () => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		axios({
			method: 'GET',
			url: `${API}public/gallery`,
		})
			.then(({ data }) => {
				return resolve(data);
			})
			.catch((err) => responseError(reject, dispatch, err));
	});
};
export const createGallery = ({
	description = '',
	files = [],
	type = 'image',
}) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		axios({
			method: 'POST',
			url: `${API}gallery`,
			data: {
				description,
				files,
				type,
			},
			headers: {
				Authorization: getState().token,
			},
		})
			.then(({ data }) => {
				return resolve(data);
			})
			.catch((err) => responseError(reject, dispatch, err));
	});
};
export const uploadAttach = (file) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		let formData = new FormData();
		formData.append('file', file);
		axios({
			method: 'POST',
			url: `${API}upload`,
			data: formData,
			headers: {
				Authorization: getState().token,
				'Content-Type': 'multipart/form-data',
			},
		})
			.then(({ data }) => {
				return resolve(data);
			})
			.catch((err) => responseError(reject, dispatch, err));
	});
};
export const getAddressGoogle = (lat, lng) => {
	return new Promise((resolve, reject) => {
		window.geocoder.geocode(
			{
				country: 'MX',
				location: {
					lat,
					lng,
				},
			},
			(results, status) => {
				if (status == 'OK') {
					const [first] = results;
					return resolve(first);
				} else
					return reject({
						error: true,
						message: `Geocode was not successful for the following reason: ${status}`,
						status: status,
					});
			}
		);
	});
};

export const createDonation = ({ amount, organization }) => (
	dispatch,
	getState
) => {
	return new Promise((resolve, reject) => {
		axios({
			method: 'POST',
			url: `${API}donation`,
			headers: {
				Authorization: getState().token,
			},
			data: {
				amount,
				organization,
			},
		})
			.then(({ data }) => {
				return resolve(data);
			})
			.catch((err) => responseError(reject, dispatch, err));
	});
};

export const findDonations = ({
	page = 1,
	organization = '0',
	startDate = false,
	endDate = false,
}) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		axios({
			method: 'GET',
			url: `${API}donation`,
			params: {
				page,
				organization,
				startDate,
				endDate,
			},
			headers: {
				Authorization: getState().token,
			},
		})
			.then(({ data }) => {
				return resolve(data);
			})
			.catch((err) => responseError(reject, dispatch, err));
	});
};
