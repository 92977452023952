import React, { useState } from 'react';
import Modal from 'react-modal';
import { customStyles } from '../../config/const';
import { translate } from 'react-translate';
import Button from '../form/Button';

const ModalBepensa = (props) => {
	const { visible = false, onClose, t } = props;

	const closeModal = (reload = false) => {
		if (onClose) onClose(reload);
	};

	return (
		<Modal
			isOpen={visible ? true : false}
			onRequestClose={() => closeModal(false)}
			style={customStyles}
			className="modal"
			contentLabel=""
			ariaHideApp={false}
		>
			<button onClick={() => closeModal(false)} className="modal-close">
				<i className="material-icons">close</i>
			</button>
			<div className="modal-header mt-4"></div>
			<div className="modal-body mt-4 mb-4">
				<h1 className="mt-5">FUNDACIÓN BEPENSA</h1>

				<p className="poppins">
					<p>
						<b>BANCO:</b> SANTANDER
						<br />
						<b>BENEFICIARIO:</b> FUNDACIÓN BEPENSA, AC
						<br />
						<b>CUENTA:</b> 65501905415
					</p>
				</p>
				<p>
					Podemos otorgar deducible de impuestos por cada donación al formar
					parte de las organizaciones donatarias autorizadas por la SHCP para
					expedir recibos deducibles de impuestos. Para solicitar el tuyo, envía
					tu ficha de depósito o comprobante de transferencia bancaria y tus
					datos fiscales al correo{' '}
					<a href="mailto:yucatan.solidario@gmail.com">
						yucatan.solidario@gmail.com
					</a>
				</p>
			</div>
		</Modal>
	);
};

export default translate('modal')(ModalBepensa);
