import React from 'react';
import { UISref, UISrefActive } from '@uirouter/react';
import { translate } from 'react-translate';
import { logout } from '../redux/actions';
import { connect } from 'react-redux';
const HeaderAdmin = (props) => {
	const { t, rol } = props;

	const logout = () => {
		props.logout();
		window.location.href = '/iniciar-sesion';
	};
	return (
		<header>
			<div className="container">
				<div className="row">
					<div className="col">
						<div className="header-content">
							<UISref to="admin.home">
								<a>
									<img src="/assets/images/favicon-01.svg" className="logo" />
								</a>
							</UISref>
							<ul>
								{rol === 'admin' ? (
									<li>
										<UISrefActive class="active">
											<UISref to="admin.gallery">
												<a>{t('gallery')}</a>
											</UISref>
										</UISrefActive>
									</li>
								) : null}
								{rol === 'admin' ? (
									<li>
										<UISrefActive class="active">
											<UISref to="admin.supportreceived">
												<a>{t('supportsreceived')}</a>
											</UISref>
										</UISrefActive>
									</li>
								) : null}
								<li>
									<UISrefActive class="active">
										<UISref to="admin.donate">
											<a>DONATIVOS</a>
										</UISref>
									</UISrefActive>
								</li>
								<li>
									<a onClick={logout}>CERRAR SESIÓN</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};
const MapStateToProps = ({ user }) => {
	return {
		rol: user.rol || 'invited',
	};
};

export default connect(MapStateToProps, { logout })(
	translate('header')(HeaderAdmin)
);
