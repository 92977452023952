import React from 'react';
import { UISref } from '@uirouter/react';
const Footer = (props) => {
	return (
		<footer>
			<div className="container">
				<div className="row">
					<div className="col-12 col">
						<div className="footer-content">
							<img className="logofoot" src="/assets/images/logogobiernos.png" />
							<span>
								Yucatan.Solidario.mx es un esfuerzo colaborativo de apoyo a la
								ciudadanía durante la contingencia sanitaria del COVID-19
								patrocinado por el Gobierno del Estado de Yucatán 2018-2024,
								Para dudas y comentarios, escribenos al correo{' '}
								<a href="mailto:yucatan.solidario@gmail.com">
									yucatan.solidario@gmail.com
								</a>
								.{' '}
								<UISref to="layout.aviso">
									<a>Aviso de privacidad</a>
								</UISref>
							</span>
							<a
								target="_new"
								style={{
									textDecoration: 'none',
									color: '#CCCCCC',
									marginTop: 20,
									display: 'flex',
									alignItems: 'center',
									flexDirection: 'row',
								}}
								href="https://virkapp.com"
							>
								Powered by
								<img
									src="/assets/images/logo-virk-2.svg"
									style={{ margin: 0, marginLeft: 5 }}
								/>
							</a>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
