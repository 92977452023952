import React from 'react';

import classNames from 'classnames';
const PaginationItem = (props) => {
	const {
		value = '',
		icon = false,
		disabled = false,
		onClick,
		active = false,
	} = props;

	const container = classNames('pagination-item', {
		active: active,
	});

	return (
		<button
			disabled={disabled}
			className={container}
			onClick={() => (onClick ? onClick() : null)}
		>
			{icon ? <i className="material-icons">{icon}</i> : value}
		</button>
	);
};

export default PaginationItem;
