import { combineReducers } from 'redux';
import token from './token';
import user from './user';
import opening from './modal';

const reducers = combineReducers({
	token,
	user,
	opening
});

export default reducers;
