import React, { useState } from 'react';
import Modal from 'react-modal';
import Button from '../form/Button';
import { customStyles, statusList } from '../../config/const';
import Select from '../form/Select';
import TextArea from '../form/TextArea';
import { connect } from 'react-redux';
import { updateReport } from '../../redux/actions';
const ModalStatus = (props) => {
	const {
		visible = false,
		onClose,
		action,
		params,
		status = 'pending',
		reportId,
	} = props;
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState({ error: false, message: '' });
	const [reportStatus, setReportStatus] = useState(status);
	const [comments, setComments] = useState('');
	const closeModal = (reload = false) => {
		if (onClose) onClose(reload);
	};

	const getDisabled = () => {
		let disabled = false;
		if (loading) disabled = true;
		return disabled;
	};

	const send = () => {
		setLoading(true);
		setError({ error: false, message: '' });
		props
			.updateReport(reportId, {
				status: reportStatus,
				comments: comments,
			})
			.then((response) => {
				setLoading(false);
				closeModal(response);
			})
			.catch((err) => {
				setError({
					error: true,
					message: err.message,
				});
				setLoading(false);
			});
	};
	return (
		<Modal
			isOpen={visible ? true : false}
			onRequestClose={() => closeModal(false)}
			style={customStyles}
			className="modal"
			contentLabel=""
			ariaHideApp={false}
		>
			<div className="modal-body">
				<h1>Actualizar apoyo</h1>
			</div>
			<div className="modal-body">
				<div className="row mt-4">
					<div className="col-12">
						<Select
							hideDefault={true}
							value={reportStatus}
							onChange={setReportStatus}
							name="status"
							options={statusList}
						/>
					</div>
					<div className="col-12 mt-3">
						<TextArea name="comments" value={comments} onChange={setComments} />
					</div>
				</div>
			</div>
			<div className="modal-footer mt-3">
				<Button
					onClick={() => closeModal(false)}
					className="mr-3 btn-disabled"
					name="cancel"
				/>
				<Button
					onClick={send}
					name={getDisabled() ? 'loading' : 'updated'}
					disabled={getDisabled()}
				/>
			</div>
			{error.error ? (
				<div className="error-message mt-3">{error.message}</div>
			) : null}
		</Modal>
	);
};

export default connect(null, { updateReport })(ModalStatus);
