import React from 'react';

const Aviso = (props) => {
	return (
		<div className="container pt-5">
			<div className="row pt-3">
				<div className="col col-12 col-sm-8 offset-sm-2">
					<h2 className="text-center pt-0 mt-0">
						SISTEMA PARA EL DESARROLLO INTEGRAL DE LA FAMILIA EN YUCATÁN AVISO
						DE PRIVACIDAD SIMPLIFICADO “YUCATÁN SOLIDARIO”
					</h2>
				</div>
			</div>
			<div className="row pt-3">
				<div className="col col-12 col-sm-8 offset-sm-2">
					<p className="text-justify ">
						El Sistema para el Desarrollo Integral de la Familia en Yucatán, con
						domicilio en la Avenida Alemán, número 355, de la colonia Itzimná,
						en la Ciudad de Mérida, Yucatán, México, CP. 97100, es responsable
						del tratamiento de los datos personales que nos proporcione, los
						cuales serán protegidos conforme a lo dispuesto por la Ley General
						de Protección de Datos Personales en Posesión de Sujetos Obligados,
						y demás normatividad que resulte aplicable.
					</p>
					<h3>SOBRE EL TRATAMIENTO DE LOS DATOS PERSONALES:</h3>
					<p className="text-justify">
						Con fundamento en el artículo 6 apartado A fracciones II y IV, 16
						segundo párrafo de la Constitución Política de los Estados Unidos
						Mexicanos; artículo 26 de la Ley General de Protección de Datos
						Personales en Posesión de Sujetos Obligados; artículo 25 de la Ley
						de Protección de Datos Personales en Posesión de Sujetos Obligados
						del Estado de Yucatán, hago de su conocimiento que los datos
						personales recabados serán incorporados, tratados y protegidos en la
						Página Web denominada yucatan.solidario.mx y serán utilizados, en
						forma enunciativa, más no limitativa, para: identificar, ubicar,
						comunicar, contactarle, recaudar apoyos, para generar estadísticas
						así como para enviarlos y/o transferirlos a terceros, dentro y fuera
						del territorio nacional por cualquier medio que permita la ley para
						cumplir con nuestros fines sociales. En este sentido, hacemos de su
						conocimiento que sus datos personales serán tratados y resguardados
						con base a los principios de licitud, calidad, consentimiento,
						información, finalidad, lealtad, proporcionalidad y responsabilidad,
						consagrados en la Ley General de Protección de Datos Personales en
						Posesión de Sujetos Obligados.
					</p>
					<p className="text-justify">
						Para mayor información sobre el uso de sus datos personales, puede
						consultar nuestro aviso de privacidad integral, disponible en:{' '}
						<a href="http://www.dif.yucatan.gob.mx" target="_new">
							http://www.dif.yucatan.gob.mx/
						</a>
					</p>
				</div>
			</div>
		</div>
	);
};
export default Aviso;
