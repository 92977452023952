import React, { useState } from 'react';
import { translate } from 'react-translate';
import classNames from 'classnames';

const Select = (props) => {
	const {
		t,
		name,
		onChange,
		value = '',
		required = false,
		disabled,
		options = [],
		hideDefault = false,
		className = '',
		icon = false,
	} = props;

	const [focus, setFocus] = useState(false);

	let isFocus = classNames(
		'form-control',
		{
			focus,
			noIcon: !icon,
			active: value ? true : focus,
		},
		className
	);

	const changeValue = ({ target }) => {
		if (onChange) onChange(target.value);
	};
	return (
		<div className={isFocus}>
			<div className="form-content">
				<label>
					{t(name)}
					{required ? <sup>*</sup> : null}
				</label>
				<select
					disabled={disabled}
					required={required}
					onBlur={(e) => setFocus(false)}
					onFocus={(e) => setFocus(true)}
					onChange={changeValue}
					value={value}
				>
					{hideDefault ? null : <option value={0}></option>}
					{options.map((item) => (
						<option
							value={item.value}
							disabled={item.disabled}
							key={item.value}
						>
							{item.name}
						</option>
					))}
				</select>
			</div>
		</div>
	);
};

export default translate('form')(Select);
