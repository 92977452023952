import { SET_TOKEN, CLEAR_TOKEN } from '../types';

const token = (state = false, action) => {
	switch (action.type) {
		case SET_TOKEN: {
			const { token } = action;
			return token;
		}
		case CLEAR_TOKEN: {
			return false;
		}
		default: {
			return state;
		}
	}
};

export default token;
