import { SET_USER, CLEAR_TOKEN } from '../types';

const user = (state = false, action) => {
	switch (action.type) {
		case SET_USER: {
			const { user } = action;
			return {
				...user,
			};
		}
		case CLEAR_TOKEN: {
			return false;
		}
		default: {
			return state;
		}
	}
};

export default user;
