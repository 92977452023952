import React, { useState, useEffect } from 'react';
import Button from '../../components/form/Button';
import { connect } from 'react-redux';
import { findGallery } from '../../redux/actions';
import { PERPAGE, statusList, API } from '../../config/const';
import Pagination from '../../components/pagination/';
import { UISref } from '@uirouter/react';

const Gallery = (props) => {
	const { t, $stateParams, $transition$, rol } = props;
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState($stateParams.page || 1);

	useEffect(() => {
		getData();
	}, []);

	useEffect(() => changeRoute(), [page]);

	const changeRoute = (reset = false) => {
		let params = {
			page,
		};

		$transition$.router.stateService.go('admin.gallery', params);
	};

	const getData = (reload = false) => {
		if (!reload) setLoading(true);
		props
			.findGallery(page)
			.then(({ data, total }) => {
				setData(data);
				setTotal(total);
				setLoading(false);
			})
			.catch((err) => {});
	};

	const getPagination = () => {
		if (total > 10)
			return (
				<div className="row mt-4 mb-4">
					<div className="col col-12 col-sm-10 offset-sm-1">
						<Pagination
							onChange={(page) => setPage(page)}
							total={total}
							perPage={PERPAGE}
							page={page}
						/>
					</div>
				</div>
			);
		return false;
	};

	return (
		<div className="container">
			<div className="row">
				<div className="col-12">
					<h3>Galería</h3>
				</div>
				<div className="col col-12" style={{ textAlign: 'right' }}>
					<Button
						href="admin.newgallery"
						className="el-right"
						name="addgalery"
					/>
				</div>
				<div className="col-12 mt-4">
					{data.map((item, index) => (
						<UISref
							to="admin.singlegallery"
							params={{ id: item._id }}
							key={item._id}
						>
							<a className="item-list" style={{ height: 'auto' }}>
								<h3>{item.type}</h3>
								<p>{item.description}</p>
							</a>
						</UISref>
					))}
				</div>
				<div className="col-12">{getPagination()}</div>
			</div>
		</div>
	);
};

export default connect(null, { findGallery })(Gallery);
