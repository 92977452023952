import React, { useState } from 'react';
import Modal from 'react-modal';
import { customStyles } from '../../config/const';
import { translate } from 'react-translate';
import Button from '../form/Button';
const ModalDonativo = (props) => {
	const { visible = false, onClose, folio = 'Y20300005', t } = props;

	const closeModal = (reload = false) => {
		if (onClose) onClose(reload);
	};

	return (
		<Modal
			isOpen={visible ? true : false}
			onRequestClose={() => closeModal(false)}
			style={customStyles}
			className="modal"
			contentLabel=""
			ariaHideApp={false}
		>
			<button onClick={() => closeModal(false)} className="modal-close">
				<i className="material-icons">close</i>
			</button>
			<div className="modal-header mt-4"></div>
			<div className="modal-body mt-4 mb-4">
				<h1 className="mt-5">YUCATÁN UNIDO EN LA CONTINGENCIA</h1>
				<p className="text-center mb-0 pb-0">
					Se realizará acopio de fondos por medio de donativos a la cuenta:
				</p>

				<p className="poppins">
					Titular: <b>FUNDACIÓN DEL EMPRESARIADO YUCATECO A.C.</b>
				</p>
				<p className="poppins">
					Institución Bancaria: <b>BANORTE</b>
				</p>
				<p className="poppins">
					Cuenta: <b>0232202645</b>
				</p>
				<p className="poppins">
					CLABE: <b>072910002322026451</b>
				</p>

				<p className="poppins">
					Podemos otorgar deducible de impuestos por cada donación al formar
					parte de las organizaciones donatarias autorizadas por la SHCP para
					expedir recibos deducibles de impuestos. Para solicitar el tuyo, envía
					tu ficha de depósito o comprobante de transferencia bancaria y tus
					datos fiscales al correo{' '}
					<a href="mailto:yucatan.solidario@gmail.com">
						yucatan.solidario@gmail.com
					</a>
				</p>
			</div>
		</Modal>
	);
};

export default translate('modal')(ModalDonativo);
