import React, { useState } from 'react';
import Input from '../components/form/Input';
import Button from '../components/form/Button';
import { UISref } from '@uirouter/react';
import { translate } from 'react-translate';
import ModalOpening from '../components/ModalOpening';
const Home = (props) => {
	const { t } = props;
	const [name, setName] = useState('');
	const [visible, setVisible] = useState(true);
	return (
		<div className="container">

			<div className="row pt-5">
				<div className="col col-12 col-sm-8 offset-sm-2">
					<img
						src="/assets/images/logoyucatan.svg"
						className="el-center img-responsive"
					/>
					<h3 className="text-center" style={{ fontSize: 17 }}>
						<span style={{ color: '#305FA3' }}>Yucatán.</span>
						<span style={{ color: '#79C239' }}>Solidario.</span>
						<span style={{ color: '#E7047F' }}>mx</span>
					</h3>
					<a
						href={
							'https://wa.me/?text=' +
							window.encodeURI(
								'¡Únete a Yucatán Solidario! con tu donativo apoyaremos a las familias yucatecas que más lo necesitan https://yucatan.solidario.mx'
							)
						}
						target="_new"
						className="btn btn-whastapp el-center"
					>
						<img src="/assets/images/whatsapp-icon.png" />
						<span>COMPARTE EN WHATSAPP</span>
					</a>
				</div>
			</div>
			<div className="row mt-1">
				<div className="col col-12 col-sm-10 offset-sm-1">
					<p className="text-center">
						<b>¡Bienvenido a Yucatán Solidario!</b> Las y los yucatecos somos
						gente cálida, solidaria y llena de empatía, ésta es nuestra
						oportunidad para sumarnos y ayudar a nuestros hermanos que necesitan
						de nosotros por los efectos del COVID-19. Tu ayuda es bienvenida,
						escoge la causa ciudadana que desees y aporta tu granito de arena.
					</p>
				</div>
			</div>
			<div className="row mt-4">
				<div className="col col-12 col-sm-12 col-md-6 col-lg-4">
					<div className="box-shadow center h-392">
						<div className="icon-box">
							<img
								className="el-center"
								src="/assets/images/icono-apoyar-especie.svg"
							/>
						</div>
						<h4 className="text-center purpple-color mt-0 mb-0 pt-0 pb-0">
							{t('donationkind')}
						</h4>
						<h2 className="text-center mt-0 pt-0">{t('wantsupport')}</h2>
						<p className="text-center paragraph-card mt-0">
							<b>Estamos convocando a empresas y organizaciones</b> en
							posibilidad de realizar donativos en especie por medio de nuestro
							formulario para coordinar la entrega y recepción de tu donativo de
							forma efectiva.
						</p>
						<Button
							href="layout.wantsupport"
							className="el-center"
							name="wantsupport"
						/>
					</div>
				</div>
				<div className="col col-12 col-sm-12 col-md-6 col-lg-4">
					<div className="box-shadow center h-392">
						<div className="icon-box">
							<img
								className="el-center mb-3"
								src="/assets/images/icono-donativo.svg"
							/>
						</div>
						<h4 className="text-center green-color mt-0 mb-0 pt-0 pb-0">
							{t('donationmoney')}
						</h4>
						<h2 className="text-center mt-0 pt-0">{t('donate')}</h2>
						<p className="text-center paragraph-card mt-0">
							Apóyanos con tu donativo electrónico o por depósito bancario a
							cualquiera de las cuentas de nuestras organizaciones aliadas para
							la adquisición de insumos para quienes más lo necesitan.
						</p>
						<Button
							href="layout.donatenow"
							className="btn-green"
							name="donatenow"
						/>
					</div>
				</div>
				<div className="col col-12 col-sm-12 col-md-6 offset-md-3 offset-lg-0 col-lg-4">
					<div className="box-shadow center h-392">
						<div className="icon-box">
							<img
								className="el-center mb-3"
								src="/assets/images/icono-recibir-especie.svg"
							/>
						</div>
						<h4 className="text-center pink-color mt-0 mb-0 pt-0 pb-0">
							SEGUIMOS TRABAJANDO
						</h4>
						<h2 className="text-center mt-0 pt-0">HISTORIAS DE ÉXITO</h2>
						<p className="text-center paragraph-card mt-0">
							Estamos trabajando arduamente para lograr llegar a la mayor
							cantidad de hogares yucatecos por medio de nuestras organizaciones
							aliadas y voluntarios que ponen su mayor esfuerzo por y para
							Yucatán.
						</p>
						<Button
							href="layout.historias"
							className="el-center btn-pink"
							name="viewHistories"
						/>
					</div>
				</div>
			</div>
			<div className="row mt-3">
				<div className="col col-12">
					<h3
						className="text-center"
						style={{ color: '#305FA3', fontSize: 18 }}
					>
						GRACIAS AL APOYO DE EMPRESAS SOLIDARIAS
					</h3>
					<div className="row-items">
						<div className="item-logo">
							<img src="/assets/images/colatinco.jpg" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/keken.jpg" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/zelma-kooler.jpg" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/gal.jpg" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/suthern-valley.jpg" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/bachoco.jpg" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/porco.jpg" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/rancho_alegre.jpg" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/santafe.jpg" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/agroyuc.jpg" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/logo-gn.png" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/logo-papayal.png" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/logo-electrolic.png" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/logo-crio.png" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/logocompasur.png" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/logofp.png" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/thebest.png" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/logoanahuac.png" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/logoara.png" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/logotelesur.png" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/logopropulsa.png" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/logo130steakhouse.png" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/logowatchwatermexico.png" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/logosandias.png" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/logoinsectos.png" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/logofundaciónvivepeninsular2.png" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/logospc.png" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/logodifasa.png" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/logovenados.png" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/logogmp.png" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/logorisk.png" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/logoroche.png" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/logosalsol.png" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/logocfe.png" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/logomedsure.png" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/logoseasons.png" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/logoskyrka.png" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/logomodelo.png" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/logoscan.png" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/logodonde.png" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/logoheineken.png" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/inava.png" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/mayakin.png" />
						</div>
						<div className="item-logo">
							<img src="/assets/images/fundacionherdez.png" />
						</div>
					</div>
				</div>
			</div>
			<div className="row mt-3">
				<div className="col col-12 col-sm-10 offset-sm-1">
					<h3
						className="text-center"
						style={{ color: '#E81586', fontSize: 18 }}
					>
						EN COORDINACIÓN CON ORGANIZACIONES DE LA SOCIEDAD CIVIL
					</h3>
					<p className="text-center">
						Si deseas conocer más sobre las organizaciones y movimientos aliados
						a esta iniciativa, visita nuestra sección{' '}
						<UISref to="layout.orgs">
							<a>
								<b>ORGANIZACIONES</b>
							</a>
						</UISref>
					</p>
				</div>
			</div>
			<div className="row mt-3 mb-3">
				<div className="col col-12 col-sm-6 col-md-3 mb-3">
					<div className="card-logo">
						<img
							className="el-center logo-small"
							src="/assets/images/Logo_bamx.png"
						/>
					</div>
					<p className="text-center font-14">
						Somos una Asociación Civil sin fines de lucro que trabaja desde hace
						23 años en Mérida, promovemos la participación social para rescatar
						el recurso alimentario no comercializable y con esto apoyamos a
						personas que se encuentren en situación de pobreza y carencia
						alimentaria. Pertenecemos a la red nacional de más de 55 Bancos de
						Alimentos en todo el País.
					</p>
				</div>
				<div className="col col-12 col-sm-6 col-md-3 mb-3">
					<div className="card-logo">
						<img
							className="el-center logo-small"
							src="/assets/images/Logo_fundacionporlasalud.png"
						/>
					</div>
					<p className="text-center font-14">
						Somos una fundación sin fines de lucro, que trabaja en pro de la
						salud de la gente más necesitada del Estado de Yucatán. Fundada
						constitutivamente desde el día 25 de marzo del 2004 en la ciudad de
						Mérida.
					</p>
				</div>
				<div className="col col-12 col-sm-6 col-md-3 mb-3">
					<div className="card-logo">
						<img
							className="el-center logo-small"
							src="/assets/images/logo-bepensa.svg"
						/>
					</div>
					<p className="text-center font-14">
						Fundación Bepensa desde sus orígenes, hace más de 15 años, siempre
						ha buscado llevar el bienestar a las comunidades donde las empresas
						de Bepensa tienen presencia, es por ello que hoy, más que nunca es
						momento de permanecer seguros y tender la mano a quien más lo
						necesite.
					</p>
					<p className="text-center font-14">
						Parte de este compromiso es REGALOVE, programa que nace en el 2018
						como una opción para realizar donativos. Durante la crisis por el
						COVID-19 estaremos duplicando las aportaciones que se realicen a
						través de esta plataforma.
					</p>
				</div>
				<div className="col col-12 col-sm-6 col-md-3 mb-3">
					<div className="card-logo">
						<img
							className="el-center logo-small"
							src="/assets/images/Logo_yucatanunido.png"
						/>
					</div>
					<p className="text-center font-14">
						Somos un grupo de organizaciones de la sociedad civil encabezadas
						por los Clubes Rotarios de Yucatán, la Fundación del Empresariado
						Yucateco A.C., la Asociación de Scouts de Yucatán y el colectivo de
						Empresas Socialmente Responsables, Aliarse por Yucatán. Tomamos la
						decisión de emprender una gran cruzada para convocar al pueblo
						yucateco a sumarse en un acto comunitario de solidaridad.
					</p>
				</div>
			</div>
		</div>
	);
};

export default translate('home')(Home);
