import React, { useState } from 'react';
import Attach from '../../components/Attach';
import TextArea from '../../components/form/TextArea';
import Button from '../../components/form/Button';
import { AWS } from '../../config/const';
import { connect } from 'react-redux';
import { createGallery } from '../../redux/actions';
import Select from '../../components/form/Select';

const AddGallery = (props) => {
	const [description, setDescription] = useState('');
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [type, setType] = useState('imagen');

	const onChangeFile = (file) => {
		console.log(file);
		setData([...data, file]);
		console.log(data);
	};

	const removeFile = (index) => {
		setData([...data.slice(0, index), ...data.slice(index + 1)]);
	};

	const disabled = () => {
		let disabled = false;
		if (loading) disabled = true;
		if (data.length == 0) disabled = true;

		return disabled;
	};
	const send = () => {
		setLoading(true);
		props
			.createGallery({
				type,
				description,
				files: data,
			})
			.then((response) => {
				setLoading(false);
				setDescription('');
				setData([]);
				setType('imagen');
				console.log(response);
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	};

	return (
		<div className="container">
			<div className="row pt-3">
				<div className="col col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
					<div className="box-shadow single">
						<div className="row">
							<div className="col-12 mb-4">
								<Select
									hideDefault={true}
									name="type"
									value={type}
									onChange={setType}
									options={[
										{
											name: 'Imagen',
											value: 'imagen',
										},
										{
											name: 'Video',
											value: 'video',
										},
									]}
								/>

								<TextArea
									name="description"
									className="mb-0"
									value={description}
									onChange={setDescription}
								/>
								<p style={{ fontSize: 12 }}>
									Usa ** palabra ** para añadir negritas.
								</p>
							</div>
						</div>
						<div className="row">
							{data.map((item, index) => (
								<div
									className="col-12 d-flex justify-content-between mb-3"
									key={index}
								>
									<a href={`${AWS}${item.fd}`} target="_new">
										{item.filename}
									</a>
									<button className="trash" onClick={() => removeFile(index)}>
										<i className="material-icons">delete</i>
									</button>
								</div>
							))}
						</div>
						<div className="row">
							<div className="col-12">
								{type === 'video' ? (
									<p>
										Sube primero la imagen previa del video y despues el archivo
										MP4
									</p>
								) : null}
								<Attach onChange={onChangeFile} />
							</div>
							<div className="col-12 mt-4">
								<Button
									disabled={disabled()}
									className="el-center"
									name="saved"
									onClick={send}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default connect(null, { createGallery })(AddGallery);
