import React, { useState } from 'react';
import Modal from 'react-modal';
import { customStyles } from '../../config/const';
import { translate } from 'react-translate';
import Button from '../form/Button';

const ModalSalud = (props) => {
	const { visible = false, onClose, t } = props;

	const closeModal = (reload = false) => {
		if (onClose) onClose(reload);
	};

	return (
		<Modal
			isOpen={visible ? true : false}
			onRequestClose={() => closeModal(false)}
			style={customStyles}
			className="modal"
			contentLabel=""
			ariaHideApp={false}
		>
			<button onClick={() => closeModal(false)} className="modal-close">
				<i className="material-icons">close</i>
			</button>
			<div className="modal-header mt-4"></div>
			<div className="modal-body mt-4 mb-4">
				<h1 className="mt-5">Fundación por la Salud en Yucatán A.C.</h1>

				<p>PARA RECIBIR DONATIVOS NACIONALES:</p>
				<p className="poppins">
					<p>
						<b>BANCO</b>: Santander Serfin
						<br />
						<b>BENEFICIARIO</b>: Fundación por la Salud en Yucatán A.C.
						<br />
						<b>CUENTA</b>: 92000243120 [Depósito Directo]
					</p>
					<p>
						<b>BANCO</b>: BBVA
						<br />
						<b>BENEFICIARIO</b>: Fundación por la Salud en Yucatán A.C.
						<br />
						<b>CLABE</b>: 012910001141768207
						<br />
						<b>CUENTA</b>: 0114176820
						<br />
						<b>RFC</b>: FSY04032KQ5
					</p>
				</p>
				<p>PARA DONATIVOS INTERNACIONALES:</p>
				<p>
					<b>BANCO</b>: Santander Central Hispano New York
					<br />
					<b>BENEFICIARIO</b>: Fundación por la Salud en Yucatán A.C.
					<br />
					<b>ABA</b> 026007692
					<br />
					<b>CUENTA</b>: 100015-001
				</p>

				<p className="poppins text-justify">
					Podemos otorgar deducible de impuestos por cada donación al formar
					parte de las organizaciones donatarias autorizadas por la SHCP para
					expedir recibos deducibles de impuestos. Para solicitar el tuyo, envía
					tu ficha de depósito o comprobante de transferencia bancaria y tus
					datos fiscales al correo{' '}
					<a href="mailto:yucatan.solidario@gmail.com">
						yucatan.solidario@gmail.com
					</a>
				</p>
			</div>
		</Modal>
	);
};

export default translate('modal')(ModalSalud);
