import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { uploadAttach } from '../redux/actions';
import Button from './form/Button';
import { translate } from 'react-translate';
const Attach = (props) => {
	const { className, onChange, accept = '*', t } = props;
	const [loading, setLoaing] = useState(false);
	const [error, setError] = useState({ error: false, message: '' });

	const onDrop = (acceptedFiles) => {
		const [first] = acceptedFiles;
		setError({ error: false, message: '' });
		setLoaing(true);
		props
			.uploadAttach(first)
			.then((file) => {
				setLoaing(false);
				if (onChange) return onChange(file);
			})
			.catch((err) => {
				setError({ error: true, message: err.message });
				setLoaing(false);
			});
	};

	const {
		getRootProps,
		getInputProps,
		open,
		acceptedFiles,
		isDragActive,
	} = useDropzone({ onDrop: onDrop });

	let classNamecontainer = classNames('upload-attach', className, {
		isDragActive: isDragActive,
	});

	return (
		<div>
			<div {...getRootProps({ className: classNamecontainer })}>
				<input {...getInputProps()} />
				<i className="icon-upload material-icons">cloud_upload</i>
				<p>{t('description')}</p>
				<p className="maxsize">{t('max-size')}</p>
			</div>
			{error.error ? (
				<div className="error-message mt-3">{error.message}</div>
			) : null}
		</div>
	);
};

export default translate('upload')(connect(null, { uploadAttach })(Attach));
