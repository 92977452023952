import React from 'react';
import { translate } from 'react-translate';
import classNames from 'classnames';
import CheckBox from './CheckBox';
const Checkboxes = (props) => {
	const {
		t,
		name,
		icon = false,
		onChange,
		options = [],
		value = '',
		required = false,
		className = '',
		optional = false,
		isInvalid = false,
	} = props;

	let isFocus = classNames(
		'form-control checkbox-list',
		{
			active: true,
			noIcon: !icon,
			isInvalid: isInvalid,
		},
		className
	);

	return (
		<div className={isFocus}>
			{icon ? (
				<div className="icon">
					<i className="material-icons">{icon}</i>
				</div>
			) : null}
			<div className="form-content">
				<label>
					{t(name)}
					{required ? <sup>*</sup> : null}
					&nbsp;{optional ? <span>({t('optional')})</span> : null}
				</label>
			</div>
			{options.map((item, index) => (
				<CheckBox
					onClick={() => onChange(item.value)}
					active={item.value === value}
					key={index}
					name={item.name}
					isRadio={true}
				/>
			))}
		</div>
	);
};

export default translate('form')(Checkboxes);
