import React, { useState } from 'react';
import { translate } from 'react-translate';
import Button from '../components/form/Button';
import ModalDonativo from '../components/modal/ModalDonativo';
import ModalBanco from '../components/modal/ModalBanco';
import ModalSalud from '../components/modal/ModalSalud';
import ModalBepensa from '../components/modal/ModalBepensa';
import { createDonation } from '../redux/actions';
import { connect } from 'react-redux';
import Input from '../components/form/Input';
import classNames from 'classnames';
import ModalIcay from '../components/modal/ModalIcay';

const DonateNow = (props) => {
	const { t } = props;
	const [visible, setVisible] = useState(false);
	const [donate, setDonate] = useState();
	const [visibleBanco, setVisibleBanco] = useState(false);
	const [visibleSalud, setVisibleSalud] = useState(false);
	const [visibleBepensa, setVisibleBepensa] = useState(false);
	const [visibleIcay, setVisibleIcay] = useState(false);
	const donateOrg = (organization, href = false) => {
		if (donate) {
			props.createDonation({
				organization,
				amount: donate,
			});
			if (href) window.open(href, '_new');
			setDonate(0);
		}
	};

	return (
		<div className="container pt-5">
			<div className="row pt-3">
				<div className="col col-12 col-sm-8 offset-sm-2">
					<div className="icon-box">
						<img
							className="el-center"
							src="/assets/images/icono-donativo.svg"
						/>
					</div>
					<h3 className="text-center green-color mt-3 mb-0 pt-0 pb-0">
						DONATIVOS EN DINERO
					</h3>
					<h2 className="text-center pt-0 mt-0">REALIZA UN DONATIVO</h2>
				</div>
				<div className="col col-12 col-sm-10 offset-sm-1">
					<p className="text-center">
						Estamos trabajando arduamente para lograr llegar a la mayor cantidad
						de hogares yucatecos por medio de nuestras organizaciones aliadas;
						llena el siguiente formulario y con gusto te contactaremos para
						coordinar la entrega de ayuda.
					</p>
				</div>
			</div>
			<div className="row pt-3">
				<div className="col col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 mb-4">
					<div className="" style={{ padding: 15, borderRadius: 10 }}>
						<Input
							min={0}
							value={donate}
							onChange={setDonate}
							placeholder="placerholmxn"
							name="intentMXN"
							type="number"
						/>
					</div>
				</div>
			</div>
			<div className="row mt-3">
				<div className="col col-6 col-sm-6 col-md-3">
					<div className="card-logo">
						<img src="/assets/images/Logo_bamx.png" />
					</div>

					<form
						onSubmit={() => donateOrg('bamx')}
						action="https://www.paypal.com/cgi-bin/webscr"
						method="post"
						target="_blank"
						id="comp-k8aipxz4form"
						class="ppb0form"
					>
						<input
							type="hidden"
							name="cmd"
							value="_donations"
							id="comp-k8aipxz4cmd"
							class="ppb0cmd"
						/>
						<input
							type="hidden"
							name="item_name"
							value="Banco de Alimentos de Mérida. A.C."
							id="comp-k8aipxz4item_name"
							class="ppb0item_name"
						/>
						<input
							type="hidden"
							name="item_number"
							value="D3TQC48UG4ZFC"
							id="comp-k8aipxz4item_number"
							class="ppb0item_number"
						/>
						<input
							type="hidden"
							name="bn"
							value="Wix_Donate_WPS_IL"
							id="comp-k8aipxz4bn"
							class="ppb0bn"
						/>
						<input
							type="hidden"
							name="business"
							value="direccion@bamxmerida.org.mx"
							id="comp-k8aipxz4business"
							class="ppb0business"
						/>
						<input
							type="hidden"
							name="currency_code"
							value="MXN"
							id="comp-k8aipxz4currency_code"
							class="ppb0currency_code"
						/>
						<input
							type="hidden"
							name="notify_url"
							value="https://inventory.wix.com/ecommerce/ipn/paypal"
							id="comp-k8aipxz4notify_url"
							class="ppb0notify_url"
						/>
						<input
							type="hidden"
							name="return"
							value="https://bamxmerida.wixsite.com/bamac"
							id="comp-k8aipxz4return"
							class="ppb0return"
						/>
						<input
							type="hidden"
							name="cancel_return"
							value="https://bamxmerida.wixsite.com/bamac"
							id="comp-k8aipxz4cancel_return"
							class="ppb0cancel_return"
						/>
						<input
							type="hidden"
							name="amount"
							id="comp-k8aipxz4amount"
							class="ppb0amount"
							value=""
						/>
						<button disabled={!donate} className="btn_info_donate">
							DONATIVO <br />
							ELECTRÓNICO
						</button>
					</form>
					<a
						className="donate_description text-center"
						onClick={() => setVisibleBanco(true)}
					>
						INFORMACIÓN PARA TRANSFERENCIA INTERBANCARIA
					</a>
				</div>
				<div className="col col-6 col-sm-6 col-md-3">
					<div className="card-logo">
						<img src="/assets/images/Logo_fundacionporlasalud.png" />
					</div>
					<a
						className={classNames('btn_info_donate', { disabled: false })}
						onClick={() => setVisibleSalud(true)}
					>
						INFORMACIÓN PARA <br /> TRANSFERENCIA
					</a>
					<p
						className="donate_description"
						onClick={() => setVisibleSalud(true)}
					>
						INFORMACIÓN PARA TRANSFERENCIA INTERBANCARIA
					</p>
				</div>
				<div className="col col-6  col-sm-6 col-md-3">
					<div className={classNames('card-logo', { active: donate > 0 })}>
						<img src="/assets/images/logo-bepensa.svg" />
					</div>
					<a
						onClick={() =>
							donateOrg(
								'bepensa',
								'https://regalove.fundacionbepensa.org/hacer-una-donacion/'
							)
						}
						className={classNames('btn_info_donate', { disabled: !donate })}
						href={
							donate > 0
								? 'https://regalove.fundacionbepensa.org/hacer-una-donacion/'
								: null
						}
						target="_new"
					>
						DONATIVO <br />
						ELECTRÓNICO
					</a>
					<p
						className="donate_description"
						onClick={() => setVisibleBepensa(true)}
					>
						INFORMACIÓN PARA TRANSFERENCIA INTERBANCARIA
					</p>
				</div>
				<div className="col col-6 col-sm-6 col-md-3">
					<div className="card-logo">
						<img src="/assets/images/Logo_yucatanunido.png" />
					</div>
					<a
						className={classNames('btn_info_donate', { disabled: false })}
						onClick={() => setVisible(true)}
					>
						INFORMACIÓN PARA <br /> TRANSFERENCIA
					</a>
					<p className="donate_description" onClick={() => setVisible(true)}>
						INFORMACIÓN PARA TRANSFERENCIA INTERBANCARIA
					</p>
				</div>
			</div>
			<div className="row mt-5">
				<div className="col col-12 col-sm-10 offset-sm-1">
					<p className="text-center">
						La Plataforma Web constituye un esfuerzo por{' '}
						<b>
							concentrar todas las iniciativas ciudadanas de apoyo con motivo de
							la contingencia sanitaria
						</b>
						, de tal suerte que podamos sumar esfuerzos y trabajar de forma
						organizada para llegar a la mayor cantidad de familias yucatecas.
						Agradecemos el apoyo de quienes hacemos grande a Yucatán con
						solidaridad y esfuerzo en favor de los que más lo necesitan.
					</p>
				</div>
			</div>
			<ModalDonativo visible={visible} onClose={() => setVisible(false)} />
			<ModalBanco
				visible={visibleBanco}
				onClose={() => setVisibleBanco(false)}
			/>
			<ModalSalud
				visible={visibleSalud}
				onClose={() => setVisibleSalud(false)}
			/>
			<ModalBepensa
				visible={visibleBepensa}
				onClose={() => setVisibleBepensa(false)}
			/>
			<ModalIcay visible={visibleIcay} onClose={() => setVisibleIcay(false)} />
		</div>
	);
};

export default connect(null, { createDonation })(
	translate('donate')(DonateNow)
);
