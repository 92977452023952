import React from 'react';
import ReactDOM from 'react-dom';
import { TranslatorProvider } from 'react-translate';
import './css/index.css';
import App from './App';

import { compose, applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createLogger } from 'redux-logger';
import reducers from './redux/reducers';
import thunk from 'redux-thunk';
import { PersistGate } from 'redux-persist/integration/react';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';

const loggerMiddleware = createLogger({ predicate: () => false });
const persistedReducer = persistReducer(
	{
		key:
			process.env.NODE_ENV === 'development' ? 'suministrosdev' : 'suministros',
		storage,
		blacklist: ['filter'],
	},
	reducers
);

function configureStore(initialState) {
	const enhancer = compose(applyMiddleware(thunk, loggerMiddleware));
	return createStore(persistedReducer, initialState, enhancer);
}

const initialState = {};
export const store = configureStore(initialState);
export const persistor = persistStore(store);

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<TranslatorProvider translations={require('./i18n/es.json')}>
					<App />
				</TranslatorProvider>
			</PersistGate>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);
