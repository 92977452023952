import React, { useState, useEffect } from 'react';
import ListItem from '../../components/ListItem';
import { connect } from 'react-redux';
import { findReports } from '../../redux/actions';
import { PERPAGE, statusList, API } from '../../config/const';
import Pagination from '../../components/pagination/';
import Select from '../../components/form/Select';
import Search from '../../components/Search';

const Supportreceived = (props) => {
	const type = 'havesupport';
	const { t, $stateParams, $transition$ } = props;
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState($stateParams.page || 1);
	const [status, setStatus] = useState($stateParams.status || '0');
	const [s, setS] = useState(
		$stateParams.s !== 'undefined'
			? window.decodeURIComponent($stateParams.s)
			: false
	);

	useEffect(() => {
		getData();
	}, []);

	useEffect(() => changeRoute(), [page, status]);
	useEffect(() => {
		if ($stateParams.s !== s) changeRoute(true);
	}, [s]);

	const changeRoute = (reset = false) => {
		let params = {
			status,
			page: reset ? 1 : page,
			s: s,
		};

		$transition$.router.stateService.go('admin.supportreceived', params);
	};

	const getPagination = () => {
		if (total > 10)
			return (
				<div className="row mt-4 mb-4">
					<div className="col col-12 col-sm-10 offset-sm-1">
						<Pagination
							onChange={(page) => setPage(page)}
							total={total}
							perPage={PERPAGE}
							page={page}
						/>
					</div>
				</div>
			);
		return false;
	};

	const getData = (reload = false) => {
		if (!reload) setLoading(true);
		let params = {
			page,
			status,
		};
		if (s) params.s = s;
		props
			.findReports(type, params)
			.then(({ data, total }) => {
				setData(data);
				setTotal(total);
				setLoading(false);
			})
			.catch((err) => {});
	};

	return (
		<div className="container">
			<div className="row">
				<div className="col-12">
					<h3>APOYOS RECIBIDOS</h3>
				</div>
				<div className="col-12 mb-3">
					<div className="row">
						<div className="col col-12" style={{ textAlign: 'right' }}>
							<a
								href={`${API}report/donwload?authorization=${props.token}`}
								className="link"
							>
								Descargar datos
							</a>
						</div>
					</div>
					<div className="row">
						<div className="col col-12 col-lg-8">
							<Search
								value={s}
								onChange={(value) => setS(value)}
								placeholder="searchFolio"
							/>
						</div>
						<div className="col col-12 col-lg-4">
							<Select
								hideDefault={true}
								name="status"
								value={status}
								onChange={setStatus}
								options={[
									{
										name: 'Todos',
										value: '0',
									},
									...statusList,
								]}
							/>
						</div>
					</div>
				</div>
				<div className="col-12">
					{data.map((item, index) => (
						<ListItem data={item} key={item._id} />
					))}
				</div>
				<div className="col-12">{getPagination()}</div>
			</div>
		</div>
	);
};

const MapStateToProps = ({ token }) => {
	return {
		token,
	};
};
export default connect(MapStateToProps, { findReports })(Supportreceived);
