import React, { useState } from 'react';
import { translate } from 'react-translate';
import { formatPhone, validEmail } from '../config/utils';
import { connect } from 'react-redux';
import Input from '../components/form/Input';
import Select from '../components/form/Select';
import TextArea from '../components/form/TextArea';
import Button from '../components/form/Button';
import InputMap from '../components/form/InputMap';
import { createReport } from '../redux/actions';
import ModalReport from '../components/modal/ModalReport';
import { categoryList } from '../config/const';
const WantSupport = (props) => {
	const { t } = props;
	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	const [category, setCategory] = useState('0');
	const [articles, setArticles] = useState([{ title: '' }]);
	const [loading, setLoading] = useState(false);
	const [address, setAddress] = useState(false);
	const [error, setError] = useState({ error: false, message: '' });
	const [folio, setFolio] = useState('');
	const [visibleModal, setVisibleModal] = useState(false);
	const [businessName, setBusinessName] = useState('');
	const updateArticle = (index, key, value) => {
		let newData = [...articles][index];
		newData[key] = value;
		setArticles([
			...articles.slice(0, index),
			newData,
			...articles.slice(index + 1),
		]);
	};

	const _reset = () => {
		setName('');
		setPhone('');
		setEmail('');
		setCategory('0');
		setBusinessName('');
		setArticles([{ title: '' }]);
		setAddress(false);
	};

	const send = () => {
		setLoading(true);
		setError({
			error: false,
			message: '',
		});
		props
			.createReport({
				name,
				phone,
				email,
				category,
				articles,
				address,
				businessName,
			})
			.then((report) => {
				setLoading(false);
				_reset();
				setFolio(report.folio);
				setVisibleModal(true);
			})
			.catch((err) => {
				setError({
					error: true,
					message: err.message,
				});
				setLoading(false);
			});
	};
	const addArticle = (index) => setArticles([...articles, { title: '' }]);

	const removeItem = (index) =>
		setArticles([...articles.slice(0, index), ...articles.slice(index + 1)]);

	return (
		<div className="container pt-5">
			<div className="row pt-3">
				<div className="col col-12 col-sm-8 offset-sm-2">
					<div className="icon-box">
						<img
							className="el-center"
							src="/assets/images/icono-apoyar-especie.svg"
						/>
					</div>
					<h3 className="text-center purpple-color mt-3 mb-0 pt-0 pb-0">
						DONATIVOS EN ESPECIE
					</h3>
					<h2 className="text-center pt-0 mt-0">QUIERO APOYAR</h2>
				</div>
				<div className="col col-12 col-sm-10 offset-sm-1">
					<p className="text-center">
						<b>Estamos convocando a empresas y organizaciones</b> en posibilidad
						de realizar donativos en especie. Llena el siguiente formulario y nos pondremos en
						contacto contigo para coordinar la entrega y recepción de forma
						efectiva de{' '}
						<b>
							alimentos perecederos y no perecederos, medicamentos e insumos
							médicos, bienes y servicios diversos
						</b>
						. De antemano y a nombre de todas las familias yucatecas,
						agradecemos tu solidaridad y ayuda.
					</p>
				</div>
			</div>
			<div className="row pt-3">
				<div className="col col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
					<div className="box-shadow">
						<Select
							name="category"
							value={category}
							onChange={setCategory}
							hideDefault={true}
							options={[
								{
									name: 'Selecciona',
									disabled: true,
									value: '0',
								},
								...categoryList,
							]}
						/>

						<InputMap name="location" onChange={setAddress} />
						{articles.map((item, index) => (
							<div key={index} className="repeat-item">
								{articles.length > 1 ? (
									<button onClick={() => removeItem(index)}>
										<i className="material-icons">remove</i>
									</button>
								) : null}
								<TextArea
									placeholder="detailsArticle"
									name={index === 0 ? 'article' : 'otherarticle'}
									className="mb-4"
									value={item.title}
									onChange={(article) => updateArticle(index, 'title', article)}
								/>
							</div>
						))}
						<a className="mb-4 add-href" onClick={addArticle}>
							<i className="material-icons">add</i>
							<span>{t('addArticle')}</span>
						</a>

						<Input
							name="businessName"
							value={businessName}
							onChange={setBusinessName}
						/>
						<Input
							name="name"
							value={name}
							onChange={setName}
							placeholder="detailname"
						/>

						<Input
							maxLength={14}
							type="tel"
							name="phone"
							placeholder="detailphone"
							value={formatPhone(phone)}
							onChange={setPhone}
						/>

						<Input
							isInvalid={email && !validEmail(email) ? true : false}
							type="email"
							name="email"
							value={email}
							onChange={setEmail}
						/>

						<Button
							disabled={loading}
							onClick={send}
							className="el-center"
							name={loading ? 'loading' : 'send'}
						/>

						<div className="col-12 mt-4">
							{error.error ? (
								<div className="error-message">{error.message}</div>
							) : null}
						</div>
					</div>
				</div>
			</div>
			<ModalReport
				visible={visibleModal}
				onClose={() => setVisibleModal(false)}
				folio={folio}
			/>
		</div>
	);
};

export default connect(null, { createReport })(
	translate('wantsupport')(WantSupport)
);
