import React from 'react';
import { UIView } from '@uirouter/react';
import Header from '../../components/HeaderAdmin';
import Footer from '../../components/Footer';

const LayoutAdmin = (props) => {
	return (
		<div>
			<Header />
			<div className="wrapper">
				<UIView />
			</div>
			<Footer />
		</div>
	);
};

export default LayoutAdmin;
