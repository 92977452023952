import axios from 'axios';
import { API } from '../../config/const';
import { responseError } from '../../config/utils';

export const updateReport = (reportId, { status, comments = '' }) => (
	dispatch,
	getState
) => {
	return new Promise((resolve, reject) => {
		axios({
			method: 'POST',
			url: `${API}report/${reportId}`,
			headers: {
				Authorization: getState().token,
			},
			data: {
				status: status,
				comments: comments,
			},
		})
			.then(({ data }) => {
				return resolve(data);
			})
			.catch((err) => responseError(reject, dispatch, err));
	});
};
export const removeReport = ({ id }) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		axios({
			method: 'DELETE',
			url: `${API}report/${id}`,
			headers: {
				Authorization: getState().token,
			},
		})
			.then(({ data }) => {
				return resolve(data);
			})
			.catch((err) => responseError(reject, dispatch, err));
	});
};

export const findReport = (id) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		axios({
			method: 'GET',
			url: `${API}report/${id}`,
			headers: {
				Authorization: getState().token,
			},
		})
			.then(({ data }) => {
				return resolve(data);
			})
			.catch((err) => responseError(reject, dispatch, err));
	});
};
export const findReports = (
	type = 'havesupport',
	{ page = 1, s, status = '0' }
) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		axios({
			method: 'GET',
			url: `${API}report`,
			params: {
				page,
				s,
				type,
				status,
			},
			headers: {
				Authorization: getState().token,
			},
		})
			.then(({ data }) => {
				return resolve(data);
			})
			.catch((err) => responseError(reject, dispatch, err));
	});
};

export const createReport = (
	{
		name = '',
		phone = '',
		email = false,
		address = {},
		category = false,
		articles = [],
		whyfired = '',
		businessName = '',
		kindPerson = '',
		CURP = '',
	},
	type = 'havesupport'
) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		axios({
			method: 'POST',
			url: `${API}report`,
			data: {
				name,
				phone,
				email,
				address,
				category,
				articles,
				whyfired,
				type,
				businessName,
				kindPerson,
				CURP,
			},
		})
			.then(({ data }) => {
				return resolve(data);
			})
			.catch((err) => responseError(reject, dispatch, err));
	});
};
